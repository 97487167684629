import { defineStore } from "pinia";
import config from "../../config";
import { i18n } from "../i18n";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    currentLocale: config.locales.default,
    defaultLocale: config.locales.default,
    fallbackLocale: config.locales.fallback,
    supportedLocales: config.locales.supported,
  }),
  actions: {
    setLocale(newLocale) {
      this.currentLocale = newLocale; // Set value in store
      i18n.locale.value = newLocale; // Change app language
    },
    getOtherLocale() {
      const locales = this.supportedLocales.slice();

      // TODO works only for 2 supported languages, add dropdown
      const index = locales.indexOf(this.currentLocale);
      if (index !== -1) {
        locales.splice(index, 1);
      }

      return locales[0];
    },
  },
});
