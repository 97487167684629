<template>
  <div :class="[
    'intermediate',
    open || stops.length == 3 ? 'intermediate_open' : '',
  ]" v-if="stops.length > 2">
    <div v-if="stops.length > 3" class="intermediate__head"> {{ headTitle() }} </div>
    <div class="intermediate__body">
      <div v-for="(stop, i) in filterStops()" class="intermediate__stop" :key="i">
        <div class="intermediate__name">{{ stop.name }}</div>
        <div class="intermediate__time">{{ timeFormat(stop.departure) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Intermediate",
  props: ["open", "stops"],
  methods: {
    filterStops() {
      return this.stops.slice(1, -1);
    },
    headTitle() {
      var n = this.stops.length - 2;
      return this.$t("intermediate_stop", n);
    },
    timeFormat(timestamp) {
      return dayjs(timestamp).format("H:mm");
    },
  },
};
</script>

<style lang="scss">
.intermediate {
  margin-bottom: 0.25rem;
  display: none;

  .itinerary_open & {
    display: block;
  }

  &__head {
    font-size: 0.75rem;
    opacity: 0.75;
    display: flex;
    width: fit-content;
    align-items: center;

    .leg:hover & {
      text-decoration: underline;
    }

    .intermediate_open & {
      margin-bottom: 0.5rem;
    }

    &:before {
      content: "";
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.25rem;
      background-image: url(../images/angle-down-black.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: 0.2s transform ease-in-out;

      .intermediate_open & {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    display: none;

    .intermediate_open & {
      display: block;
    }
  }

  &__stop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 0.75rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  &__name {
    font-weight: 600;
    margin-right: .5rem;
  }

  &__time {
    opacity: 0.75;
  }
}
</style>
