<template>
  <div :class="['advanced', open ? 'advanced_open' : '']">
    <div @click="toggleOpen()" class="advanced__head" tabindex="0">
      <div class="advanced__arrow">
        <Icon file="angle-down-black.svg" />
      </div>
      <div class="advanced__title"> {{ headString }} </div>
    </div>
    <div class="advanced__body">
      <div class="advanced__content">
        <div class="advanced__category">
          <div class="advanced__radio">
            <label class="advanced__label" tabindex="0">
              <input type="radio" name="searchfor" v-model="generalStore.optimize" value="transfers" />
              <div class="advanced__option">{{ $t("fewer_transfers") }}</div>
            </label>
            <label class="advanced__label" tabindex="0">
              <input type="radio" name="searchfor" v-model="generalStore.optimize" value="quick" />
              <div class="advanced__option">{{ $t("short_duration") }}</div>
            </label>
          </div>
        </div>
        <!-- <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">{{ $t("max_transfers") }}</div>
            <div class="advanced__value">{{ this.maxTransfers }}</div>
          </div>
          <vue-slider v-model="this.maxTransfers" :height="2" :min="0" :max="5" :step="1" :adsorb="true"
            :tooltip="'none'" :dragOnClick="true" @drag-end="updateTransfers()"></vue-slider>
        </div> -->
        <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">{{ $t("max_walk_distance") }}</div>
            <div class="advanced__value">{{ walkDistanceString }}</div>
          </div>
          <vue-slider v-model="this.maxWalkDistance" :height="2" :min="0" :max="5000" :interval="100" :adsorb="true"
            :tooltip="'none'" :dragOnClick="true" @drag-end="updateWalkDistance()"></vue-slider>
        </div>
        <div class="advanced__category">
          <div class="advanced__top">
            <div class="advanced__heading">{{ $t("walk_speed") }}</div>
            <div class="advanced__value">{{ walkSpeedString }}</div>
          </div>
          <vue-slider v-model="this.walkSpeed" :height="2" :min="0" :max="3" :step="1" :adsorb="true" :tooltip="'none'"
            :dragOnClick="true" @drag-end="updateWalkSpeed()"></vue-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { useGeneralStore } from "@/stores/general";

export default {
  name: "Advanced",
  components: {
    Icon,
    VueSlider,
  },
  data() {
    return {
      open: false,

      /**
       * TODO: remove the following properties and add :lazy="true" to vue-slider.
       *       the displayed value will not change until drag-end, this value has to be shown somewhere.
       */
      maxTransfers: this.generalStore.maxTransfers,
      maxWalkDistance: this.generalStore.maxWalkDistance,
      walkSpeed: this.generalStore.walkSpeed,

      walkSpeedLabels: {
        0: this.$t("stroll"),
        1: this.$t("walk"),
        2: this.$t("trot"),
        3: this.$t("sprint"),
      },
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    return { generalStore };
  },
  computed: {
    walkDistanceString: function () {
      if (this.maxWalkDistance == 0) {
        return this.$t("auto")
      }
      if (this.maxWalkDistance < 1000) {
        return this.maxWalkDistance + " m";
      } else {
        return Math.floor(this.maxWalkDistance / 100) / 10 + " km";
      }
    },
    walkSpeedString: function () {
      return this.walkSpeedLabels[this.walkSpeed];
    },
    headString: function () {
      return this.open ? this.$t("fewer_settings") : this.$t("more_settings");
    },
  },
  methods: {
    updateTransfers() {
      this.generalStore.maxTransfers = this.maxTransfers;
    },

    updateWalkDistance() {
      this.generalStore.maxWalkDistance = this.maxWalkDistance;
    },

    updateWalkSpeed() {
      this.generalStore.walkSpeed = this.walkSpeed;
    },

    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss">
.advanced {
  margin: .5rem 1rem;

  &__head {
    display: flex;
    width: fit-content;
    align-items: center;
    opacity: .75;
    text-decoration: underline;
    cursor: pointer;
  }

  &__arrow {
    border-radius: 100px;
    width: 0.65rem;
    height: 0.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.45rem;

    img {
      width: 100%;
      height: 100%;
      // opacity: 0.55;
      transition: 0.2s transform ease-in-out;

      .advanced_open & {
        transform: rotate(180deg);
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 0.85rem;
    // opacity: 0.5;
  }

  &__body {
    position: relative;
    overflow: hidden;
    font-size: 0.85rem;
    max-height: 0;
    transition: max-height 0.2s ease-in-out;
    overflow: hidden;
    border-color: $c-light-gray;

    .advanced_open & {
      max-height: 300px;
    }

    // max-height: 0 + padding-top: .5rem != height: 0
    // A pseudoelement with the desired height can push the content down while
    // having max-height: 0
    &:before {
      content: "";
      display: block;
      height: 0.5rem;
    }
  }

  &__content {
    padding: 1rem;
    box-shadow: inset 0 0 0 1px $c-light-gray;
    border-radius: $border-radius-medium;
  }

  &__category {
    margin-bottom: 0.65rem;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  &__top {
    display: flex;
    font-weight: 600;
  }

  &__heading {
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    font-weight: 400;
  }

  &__value {
    font-weight: 700;
  }

  &__radio {
    display: flex;
  }

  &__label {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    cursor: pointer;

    &:first-child {
      margin-right: 1rem;
    }

    input {
      display: none;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/option.svg);
      margin-right: 0.35rem;
    }

    input[type="radio"]:checked+& {
      font-weight: 700;

      &:before {
        background-image: url(../images/option-selected.svg);
      }
    }

    &:not(input[type="radio"]:checked + &):hover {
      text-decoration: underline;
    }
  }
}

.vue-slider {
  overscroll-behavior: contain;
  touch-action: none;
  cursor: pointer;
  height: 1px !important;
}

.vue-slider-rail {
  background-color: $c-light-gray !important;
}

.vue-slider-process {
  background-color: $c-blue !important;
}

.vue-slider-dot {
  transition: left 0.2s ease-in-out;
}

.vue-slider-dot-handle-focus {
  box-shadow: none;
}

.vue-slider-dot-handle {
  background-color: $c-blue;
  border-color: $c-blue !important;
}
</style>
