import { defineStore } from "pinia";
import { PlanPlaces } from "@/core/Plan";
import isEqual from "lodash.isequal";
import Itinerary from "@/core/Itinerary";

const MAX_RECENT_LENGTH = 5;

export const usePersistedStore = defineStore("persisted", {
    state: () => ({
        recentPlans: [] as PlanPlaces[],
        favoritePlans: [] as PlanPlaces[],
        savedItineraries: [] as Itinerary[],
        allowGeolocation: false as boolean,
    }),
    persist: true,
    actions: {
        toggleRememberPlan(planPlaces) {
            // If plan is already "favorited" or "recent", don't add it again
            if (
                this.favoritePlans.some((params) => isEqual(params, planPlaces))
                || this.recentPlans.some((params) => isEqual(params, planPlaces))
            ) {
                return;
            }

            // Add PlanPlaces to recent plans
            this.recentPlans.push(planPlaces);

            // Trim size of recent plans
            this.recentPlans = this.recentPlans.slice(
                Math.max(this.recentPlans.length - MAX_RECENT_LENGTH, 0)
            );
        },

        toggleFavoritePlan(planPlaces) {
            // Toggle - favorite/unfavorite

            // Check if plan is already favorited
            let isFavorited = this.favoritePlans.some((params) => isEqual(params, planPlaces));

            if (!isFavorited) {
                // Add to favorites
                this.favoritePlans.push(planPlaces);
            } else {
                // Remove from favorites
                var index = this.favoritePlans.indexOf(planPlaces);
                if (index !== -1) {
                    this.favoritePlans.splice(index, 1);
                }
            }
        },

        saveItinerary(itinerary) {
            // If is already saved, skip (this shouldn't ever happen though)
            if (
                this.savedItineraries.some((it) => isEqual(it, itinerary))
            ) {
                return;
            }

            this.savedItineraries.push(itinerary);
        },

        unsaveItinerary(itinerary) {
            this.savedItineraries = this.savedItineraries.filter((it) => {
                return !isEqual(it, itinerary);
            });
        },

        clearSaved() {
            this.savedItineraries = [];
        },
    }
});
