import Itinerary from "./Itinerary";
import Place from "./Place";

export type PlanPlaces = {
  origin: Place | null,
  destination: Place | null
}

export type PlanJSON = {
  itineraries: Itinerary[];
};

export default class Plan {
  readonly itineraries: Itinerary[];

  constructor({ itineraries = [] }: PlanJSON) {
    this.itineraries = itineraries.map((itinerary) => new Itinerary(itinerary));
  }

  isEmpty(): boolean {
    return this.itineraries.length === 0;
  }
}
