/**
 * Implements some funcionality from Axios that's missing in Fetch API, to
 * reduce page size.
 */
export function createJSONFetch(url, data = {}) {
  const urlObj = new URL(url);

  for (const attr in data) {
    const params =
      typeof data[attr] === "object"
        ? JSON.stringify(data[attr])
        : data[attr].toString();
    urlObj.searchParams.append(attr, params);
  }

  return fetch(urlObj.toString()).then((response) => response.json());
}
