<template>
  <div class="point">
    <div :class="[
      'point__inputs',
      inputFocus ? 'point__inputs_input-focus' : '',
      point?.loading ? 'point__inputs_loading' : '',
      point?.name?.primary ? 'point__inputs_filled' : '',
      point?.name?.secondary && !recording ? 'point__inputs_has-secondary' : '',
    ]">
      <input class="point__input" type="text" v-model.trim="textInput" @focus="onFocus()" @blur="onBlur()"
        @input="debouncedGetPlaces()" :placeholder="text" />
      <div class="point__loading" v-if="point?.loading">{{ loadingText }}</div>
      <div class="point__secondary"> {{ point?.name ? point.name.secondary : "" }} </div>
      <div class="point__voice" v-if="supportsRecognition">
        <div :class="[
          'point__voice-button',
          recording ? 'point__voice-button_recording' : '',
        ]" @focus="onVoiceFocus()" @click="voiceInput()" tabindex="0" @blur="onVoiceBlur()">
          <Icon file="microphone.svg" />
        </div>
      </div>
      <div class="point__dropdown">
        <Dropdown @selected="setPoint" @continue="clear(true)" @pressedEnter="this.$emit('pressedEnter')"
          :options="this.places" :open="dropdownOpen" />
      </div>
    </div>
    <div class="point__buttons">
      <div class="point__button point__button_geolocate" v-if="generalStore.supportsGeolocation" tabindex="0"
        @click="geolocate()"> {{ $t("your_location") }} </div>
      <div class="point__button point__button_map" tabindex="0" @click="$emit('selectFromMap')"> {{ $t("select_on_map")
      }} </div>
    </div>
  </div>
</template>

<script>
// TODO Rename to Place
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import { createJSONFetch } from "../utils/fetch";
import config from "../../config";
import Place from "../core/Place";
import Coords from "../core/Coords";
import debounce from "lodash.debounce";

import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  name: "Point",
  components: {
    Icon,
    Dropdown,
  },
  props: {
    isOrigin: Boolean,
    point: Object,
  },
  data() {
    return {
      supportsRecognition: false,
      recognition: false,
      textInput: "",
      inputFocus: false,
      voiceFocus: false,
      places: [],
      recording: false,
      loadingText: "",
    };
  },
  setup() {
    let generalStore = useGeneralStore();
    let persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  computed: {
    text: function () {
      if (this.point?.loading) {
        return "";
      }

      if (this.recording) {
        return this.$t("listening");
      }

      if (!this.point?.name || this.point?.name.primary == "") {
        return this.isOrigin ? this.$t("from_input") : this.$t("to_input");
      }

      return this.point.name.primary;
    },
    dropdownOpen: function () {
      return this.inputFocus || this.voiceFocus;
    },
  },
  methods: {
    geolocate() {
      // TODO obsolete, rewrite
      this.setPoint(new Place({
        loading: true
      }));
      this.loadingText = this.$t("finding_location");

      navigator.geolocation.getCurrentPosition((position) => {
        this.persistedStore.allowGeolocation = true;

        this.setPoint(new Place({
          coords: new Coords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        }));

        if (this.isOrigin) {
          this.generalStore.generateOriginName();
        } else {
          this.generalStore.generateDestinationName();
        }
        this.clearInput();
      },
        () => {
          // Failure
          this.loadingText = "";
          this.loading = false;
          alert(this.$t("geolocation_failed"));
        }
      );
    },
    initVoiceInput() {
      if (
        window.SpeechRecognition == undefined &&
        window.webkitSpeechRecognition == undefined &&
        window.mozSpeechRecognition == undefined &&
        window.msSpeechRecognition == undefined
      ) {
        return;
      }

      this.supportsRecognition = true;

      this.recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition)();

      this.recognition.continuous = false;
      this.recognition.lang = "sk-SK";
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;

      let self = this;

      this.recognition.onresult = function (event) {
        self.recording = false;
        self.textInput = event.results[0][0].transcript;
        self.debouncedGetPlaces();
      };
      this.recognition.onaudioend = function () {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onend = function () {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onerror = function () {
        self.recognition.stop();
        self.recording = false;
      };
      this.recognition.onnomatch = function () {
        self.recognition.stop();
        self.recording = false;
      };
    },
    voiceInput() {
      if (!this.supportsRecognition || this.recording) {
        return;
      }
      this.recording = true;
      this.recognition.start();
    },
    clear(clearPlaces = false, clearPoint = false) {
      if (clearPlaces) {
        // Without this condition a place could not be selected
        this.places = [];
      }

      if (clearPoint) {
        this.$emit("updatePoint", undefined);
      }


      this.textInput = "";
    },
    getPlaces() {
      if (this.textInput.length < 3) {
        this.places = [];
        return;
      }

      createJSONFetch(config.api.host + "/autocomplete", {
        query: this.textInput,
      }).then((response) => {
        if (this.textInput.length < 3) {
          this.places = [];
        } else {
          this.places = Object.freeze(response.places);
        }
      });
    },
    setPoint(place) {
      this.$emit("updatePoint", place);
      this.clearInput();
    },
    clearInput() {
      this.textInput = "";
      this.places = [];
    },
    onFocus() {
      this.inputFocus = true;
      this.clear(true, true);
    },
    onBlur() {
      this.inputFocus = false;
      this.clear();
    },
    onVoiceFocus() {
      this.voiceFocus = true;
      this.clear(true, true);
    },
    onVoiceBlur() {
      this.voiceFocus = false;
      this.clear();
    },
  },
  mounted() {
    this.initVoiceInput();
    this.debouncedGetPlaces = debounce(() => {
      this.getPlaces();
    }, 200);
  },
};
</script>

<style lang="scss">
.point {
  margin-bottom: 1rem;

  &__inputs {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &_filled input::placeholder {
      font-weight: 700 !important;

      :not(.page_dark) & {
        color: rgba($c-font-black, 0.95);
      }

      .page_dark & {
        color: rgba($c-white, 0.75);
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 10;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(../images/check-circle.svg);
      width: 0;
      height: 0;
      left: 0.75rem;
      opacity: 0;
    }

    &_filled:before {
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;
      transition: width 0.1s ease-in-out, height 0.1s ease-in-out,
        opacity 0.025s ease-in-out;
    }
  }

  &__input {
    border: none;
    @include crater($c-light-gray);

    width: 100%;
    font-size: 1.15rem;
    font-weight: 600;
    border-radius: $border-radius-medium;
    outline: none;
    padding: 1rem;
    transition: box-shadow 0.1s ease-in-out;

    :not(.page_dark) & {
      color: $c-font-black;
    }

    .page_dark & {
      color: $c-white;
    }

    .point__inputs_filled & {
      padding-left: 2.5rem !important;
    }

    .point__inputs_has-secondary & {
      padding: 0.5rem 1rem 1.5rem;
    }

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    &::placeholder {
      :not(.page_dark) & {
        color: rgba($c-black, 0.4);
      }

      .page_dark & {
        color: rgba($c-white, 0.4);
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $c-blue;
      border-color: $c-white;

      &::placeholder {
        display: none;
      }
    }
  }

  &__loading {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-weight: 600;

    &:before {
      content: "";
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/loading.svg);
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;

      // Spinning animation
      animation-name: spin;
      animation-duration: 0.35s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__secondary {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    font-size: 0.75rem;
    opacity: 0.5;
    pointer-events: none;
    display: none;

    .point__inputs_filled & {
      left: 2.5rem;
    }

    .point__inputs_has-secondary & {
      display: block;
    }
  }

  &__voice {
    position: absolute;
    right: 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__voice-button {
    border-radius: 100px;
    padding: 0.5rem;
    cursor: pointer;
    transition: border-bottom 0.1s ease-in-out,
      background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    :not(.page_dark) & {
      background-color: $c-light-gray;
    }

    .page_dark & {
      background-color: $c-black;
    }

    &_recording {
      box-shadow: 0 0 1rem -0.1rem $c-red;
      outline: none;

      :not(.page_dark) & {
        background-color: lighten($c-red, 10);
      }

      .page_dark & {
        background-color: lighten($c-red, 10);
      }

      .page_plastic & {
        border-bottom: 2px solid $c-red;
      }

      img {
        opacity: 1 !important;

        filter: invert(1);
      }
    }

    img {
      height: 1.1rem;
      width: 1.1rem;
      transition: filter 0.1s ease-in-out;
      opacity: 0.55;

      .page_dark & {
        filter: invert(1);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
  }

  &__buttons {
    display: flex;
    column-gap: 0.5rem;
    justify-content: flex-end;
  }

  &__button {
    @include button-secondary;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: .85rem;
    padding: .5rem .75rem;
  }
}
</style>
