<template>
  <div class="saved">
    <div v-if="persistedStore.savedItineraries.length == 0" class="saved__empty"> {{ $t("no_trips_pinned") }} </div>
    <div v-else>
      <div class="saved__buttons">
        <div class="saved__button saved__button_clear" tabindex="0" @click="persistedStore.clearSaved"> 📌<sub>-</sub>
          {{ $t("unpin_all") }} </div>
      </div>
      <div class="saved__itineraries">
        <Itinerary v-for="(itinerary, index) in persistedStore.savedItineraries" :key="index" :itinerary="itinerary"
          :saved="true" :focused="focused(index)" @click="focusItinerary(index)"
          @focusItinerary="(legIndex) => this.focusItinerary(index, legIndex)" />
      </div>
    </div>
  </div>
</template>

<script>
import Itinerary from "./Itinerary.vue";
import Focus, { FocusType } from "@/core/Focus";
import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  name: "Saved",
  components: {
    Itinerary,
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  methods: {
    focusItinerary(itineraryIndex, legIndex = null) {
      this.generalStore.focus = new Focus(
        FocusType.Itinerary,
        itineraryIndex,
        true,
        legIndex
      );
    },
    focused(index) {
      return this.generalStore.focus?.savedItineraries == true && index == this.generalStore.focus?.itinerary;
    }
  },
};
</script>

<style lang="scss">
.saved {
  &__empty {
    text-align: center;
    padding: 2rem 1rem 1rem;
    font-weight: 600;
  }

  &__buttons {
    display: flex;
    margin: 1rem;
  }

  &__button {
    display: flex;
    padding: 0.65rem 0.85rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: $border-radius-medium;
    font-size: 0.85rem;
    font-weight: 700;
    opacity: 0.85;

    &_clear {
      @include button-secondary;

      display: inline-flex;
      align-items: center;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    sub {
      position: relative;
      width: 0.5em;
      height: 0;
      transform: translate(-0.35em, -0.25em);
      font-size: 1.25em;
      opacity: 0.75;
    }
  }
}
</style>
