/* Modified config for GGU */

export default {
    title: "GGU Najdispoj",
    ticket: false,
    api: {
        host: "https://ns.jr.ggu.cz/api"
    },
    locales: {
        default: "sk",
        fallback: "en",
        supported: ["en", "sk"]
    },
    map: {
        vehicles: true,
        vehiclesUrl: "",
        center: {
            lat: 50,
            lng: 15
        },
        zoom: 8,
    },
    sources: [
        {
            "name": "Pražská integrovaná doprava",
            "url": "https://pid.cz/o-systemu/opendata/"
        },
        {
            "name": "KORDIS JMK a.s.",
            "url": "https://data.brno.cz/datasets/379d2e9a7907460c8ca7fda1f3e84328"
        },
        {
            "name": "Dopravní podnik města Olomouce a.s.",
            "url": "https://www.dpmo.cz/informace-pro-cestujici/jizdni-rady/jizdni-rady-gtfs/"
        },
        {
            "name": "Dopravní podnik měst Liberece a Jablonce nad Nisou a.s.",
            "url": "https://www.dpmlj.cz/opendata"
        },
        {
            "name": "OpenStreetMap contributors",
            "url": "https://www.openstreetmap.org/"
        },
    ],
}
