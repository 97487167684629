<template>
  <div class="header">
    <a class="header__link" href="/">
      <div class="header__logo">
        <img src="@/images/logo.svg" alt="Najdispoj.sk" />
      </div>
    </a>
    <a class="header__locale" @click="switchLocale">
      {{ localeStore.getOtherLocale() }}
    </a>
  </div>
</template>

<script setup>
import { useLocaleStore } from "@/stores/locale";

// Locale store
const localeStore = useLocaleStore();

const switchLocale = () => {
  localeStore.setLocale(localeStore.getOtherLocale());
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  z-index: 10;
  position: relative;

  &__link {
    @include a-unstyled;

    display: flex;
    text-decoration: none;
    position: relative;
  }

  &__locale {
    display: block;
    // font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    opacity: 0.35;
    height: fit-content;
    cursor: pointer;
  }

  &__logo {
    .page__body_dark & {
      border-color: $c-white;
    }

    img {
      height: 3.75rem;
    }
  }

  &__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 1.55rem;
    font-weight: 700;
    opacity: 0.85;
    line-height: 1.55em;
  }

  &__subtitle {
    opacity: 0.65;
    font-size: 0.65rem;
    line-height: 0.8rem;
    font-weight: 400;
    padding-left: 0.5px;
  }
}
</style>
