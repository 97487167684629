import { createI18n } from "vue-i18n";
import skLocale from "@/locales/sk";
import csLocale from "@/locales/cs";
import enLocale from "@/locales/en";
import config from "../config";

function skPlural(number) {
  if (number == 0) {
    return 0;
  } else if (number == 1) {
    return 1;
  } else if (number > 1 && number < 5) {
    return 2;
  } else {
    return 3;
  }
}

const instance = createI18n({
  legacy: false, // Enable Composition API mode
  locale: config.locales.default,
  fallbackLocale: config.locales.fallback,
  pluralRules: {
    sk: skPlural,
    cs: skPlural,
  },
  messages: {
    sk: skLocale,
    cs: csLocale,
    en: enLocale,
  },
});

export default instance;
export const i18n = instance.global;
