export default {
    search: "Vyhledávač",
    from: "Start",
    to: "Cíl",
    your_location: "Vaše poloha",
    select_on_map: "Označit na mapě",
    from_input: "Odkud cestujete?",
    to_input: "Kam cestujete?",
    departure: "Odchod",
    arrival: "Příchod",
    today: "Dnes",
    yesterday: "Včera",
    tomorrow: "Zítra",
    mon: "Pon",
    tue: "Úte",
    wed: "Stř",
    thu: "Čtv",
    fri: "Pát",
    sat: "Sob",
    sun: "Ned",
    date_format: "D.M.",
    now: "Teď",
    more_settings: "Více nastavení",
    fewer_settings: "Méně nastavení",
    fewer_transfers: "Méně přestupů",
    short_duration: "Krátké trvání",
    max_transfers: "Max. počet přestupů:",
    max_walk_distance: "Max. pěší vzdálenost:",
    walk_speed: "Rychlost chůze:",
    stroll: "Procházka",
    walk: "Chůze",
    trot: "Poklus",
    sprint: "Sprint!",
    minute: "0 minut | 1 minuta | {n} minuty | {n} minut",
    minute_genitive: "0 minut | 1 minutu | {n} minuty | {n} minut",
    minute_ago: "teď | před 1 minútou | pred {n} minutami | pred {n} minutami",
    hour: "0 hodin | 1 hodina | {n} hodiny | {n} hodin",
    intermediate_stop:
        "0 mezizastávek | 1 mezizastávka | {n} mezizastávky | {n} medzizastávek",
    searching: "Hledám spoje...",
    departs_in: "odchod za",
    walking: "Pěší přesun",
    waiting: "Čekání",
    pin: "Připnout",
    unpin: "Odepnout",
    show_on_map: "Zobrazit na mapě",
    finding_location: "Zjišťuji polohu...",
    select_origin: "Zvolte start kliknutím na libovolné místo na mapě.",
    select_destination: "Zvolte cíl kliknutím na libovolné místo na mapě.",
    unpin_all: "Odepnout všechny",
    favorite: "Oblíbené",
    recent_searches: "Poslední hledané",
    listening: "Poslouchám...",
    sms_ticket: "SMS lístek",
    sms_ticket_content: "",
    sms_send: "Poslat SMS",
    sms_to_number: "Prázdná SMS na číslo",
    data_sources: "Zdroje dat",
    data_sources_content: "Jízdní řády, informace o vozidlech, geografická data:",
    allowgeo_text:
        "Pro zobrazení vaší polohy na mapě je třeba vaše povolení.",
    allowgeo_button: "Povolit přístup k poloze",
    zones: "Zóny",
    price: "Cena",
    validity: "Platnost",
    about_content: `
        <p>
            Najdispoj je <b>open source</b> projekt umožňujúci jednoduše
            a bezplatně vytvořit a nasadit do provozu <b>moderní vyhledávač dopravních
            spojů</b>.
        </p>
        <p>
            Za cíl si klade <b>zjednodušit orientaci ve veřejné dopravě</b>
            na absolutní minimum – <b>tak, aby ji dokázal využít úplně každý</b>,
            a to včetně lidí bez jakýchkoli znalostí místních zastávek,
            nebo dopravních linek.
        </p>
        <p>
            Projekt je financován z vlastní kapsy a vyvíjen ve volném čase,
            protože <b>věříme, že veřejnost si podobnou službu zaslouží</b>.
        </p>
        <p>
            <a class="modal__link" href="https://www.facebook.com/najdispoj">facebook.com/najdispoj</a>
        </p>
        <p>
            <b>Zdrojový kód</b><br>
            Licence
            <a class="modal__link" href="https://www.gnu.org/licenses/agpl-3.0.en.html" target="_blank">
            GNU Affero General Public License v3.0
            </a><br />
            Dostupný na
            <a class="modal__link" href="https://gitlab.com/cstanislav/najdispoj" target="_blank">
            https://gitlab.com/cstanislav/najdispoj
            </a>
        </p>
    `,
    no_trips_pinned: "Momentálně nemáte připnuty žádné spoje.",
    updated: "Aktualizováno",
    vehicle_no: "Vůz č.",
    geolocation_failed: "Nepodařilo se zjistit vaši polohu.",
    no_results: "Zadání nevyhovují žádné spoje.",
    auto: "Automaticky",
};
