import { defineStore } from "pinia";
import config from "../../config";
import { createJSONFetch } from "../utils/fetch";
import Vehicle from "../core/Vehicle";

export const useMapObjectStore = defineStore("mapObject", {
  state: () => ({
    vehicles: [] as Vehicle[],
  }),
  actions: {
    fetchVehicles(callback?) {
      const api_url =
        config.map.vehiclesUrl !== ""
          ? config.map.vehiclesUrl
          : config.api.host + "/vehicles";
      createJSONFetch(api_url).then((vehicles) => {
        this.vehicles = vehicles.map((vehicle) => new Vehicle(vehicle));
        if (callback) {
          callback();
        }
      });
    },
  },
});
