<template>
  <div :class="['modeicon', 'mode_' + mode]"></div>
</template>

<script>
export default {
  name: "ModeIcon",
  props: {
    mode: String,
  },
}
</script>

<style lang="scss">
.modeicon {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0.8rem;

  @include color-by-mode(&, "", background-color);
  @include background-image-by-mode(&, "");

  &.mode_WALK {
    height: 1rem;
    background-size: contain;
    background-color: transparent;
  }
}
</style>
