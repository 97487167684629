<template>
  <div :class="[
    'vehicle-tooltip',
    `vehicle-tooltip_${vehicle.type}`
  ]" v-if="vehicle != null">
    <div class="vehicle-tooltip__line"> {{ vehicle.routeShortName }} </div>
    <div class="vehicle-tooltip__body">
      <div class="vehicle-tooltip__number">{{ $t("vehicle_no") }} {{ vehicle.number }}</div>
      <div class="vehicle-tooltip__last-change"> {{ $t("updated") }} <span class="vehicle-tooltip__last-change-value">
          {{ $t("minute_ago", minutes) }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default {
  name: "VehiclePopup",
  props: {
    vehicle: {
      type: Object
    }
  },
  data: function () {
    return {
      isMounted: false,
    };
  },
  computed: {
    minutes() {
      let result = Math.ceil(
        (
          dayjs().utc().valueOf()
          - dayjs(this.vehicle.lastUpdate * 1000).utc().valueOf()
        ) / 60000
      ) + 0; // changes -0 to 0

      if (result < 0) {
        result = 0;
      }

      return result;
    }
  },
  mounted() {
    dayjs.extend(utc);

    this.isMounted = true;
  },
}
</script>

<style lang="scss">
.vehicle-tooltip {
  border-radius: $border-radius-medium;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;

  &__line {
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    position: relative;
    color: $c-gray;

    .vehicle-tooltip_bus & {
      color: $c-mode-bus;
    }

    .vehicle-tooltip_trolley & {
      color: $c-mode-trolley;
    }

    .vehicle-tooltip_tram & {
      color: $c-mode-tram;
    }

    .vehicle-tooltip_train & {
      color: $c-mode-train;
    }

    &:before {
      content: "";
      display: block;
      width: 1.45em;
      height: 1.45em;
      border-radius: 100px;
      background-color: $c-gray;
      background-size: 0.75rem;
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 0.25rem;
      background-image: url(../images/bus.svg);

      .vehicle-tooltip_bus & {
        background-color: $c-mode-bus;
      }

      .vehicle-tooltip_tram & {
        background-image: url(../images/tram.svg);
        background-color: $c-mode-tram;
      }

      .vehicle-tooltip_trolley & {
        background-image: url(../images/trolley.svg);
        background-color: $c-mode-trolley;
      }

      .vehicle-tooltip_train & {
        background-image: url(../images/train.svg);
        background-color: $c-mode-train;
      }
    }
  }

  &__body {}

  &__last-change-value {
    font-weight: 700;
  }
}
</style>
