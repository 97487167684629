<template>
  <div :class="[
    'dropdown',
    compact ? 'dropdown_compact' : '',
    options.length == 0 || !open ? 'dropdown_hidden' : '',
  ]">
    <div class="dropdown__wrapper">
      <ul class="dropdown__list">
        <li v-for="(option, index) in options" :class="[
          'dropdown__item',
          currentItem == index ? 'dropdown__item_current' : '',
        ]" @mousedown="select(index)" :key="index" tabindex="0">
          <div v-if="(option.modes || []).length > 0"
               class="dropdown__modes">
            <ModeIcon v-for="mode of option.modes"
                      :mode="mode" />
          </div>
          <div v-else-if="option.type"
               :class="['dropdown__icon', 'dropdown__icon_' + option.type]"></div>
          <div class="dropdown__titles">
            <div class="dropdown__title">{{ option.name.primary }}</div>
            <div v-if="option.name.secondary" class="dropdown__subtitle"> {{ option.name.secondary }} </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ModeIcon from "./ModeIcon.vue";

export default {
  name: "Dropdown",
  components: {
    ModeIcon,
  },
  props: {
    options: Array,
    open: Boolean,
    compact: Boolean,
  },
  data() {
    return {
      currentItem: -1,
      hasSelected: false,
    };
  },
  mounted() {
    var self = this;

    document.addEventListener("keydown", function (e) {
      if (["ArrowUp", "ArrowDown", "Enter"].indexOf(e.code) > -1) {
        // Prevent scrolling
        e.preventDefault();
      }
      self.keyPress(e);
    });
  },
  watch: {
    open: function () {
      this.currentItem = -1;
    },
    options: function () {
      this.hasSelected = false;
      this.currentItem = -1;

      // If dropdown was scrolled and then closed, on next open it stayed scrolled
      this.scrollCurrentIntoView();
    },
    currentItem: function () {
      this.scrollCurrentIntoView();
    },
  },
  methods: {
    keyPress(e) {
      // Both dropdowns register keypresses at once -
      // keep that in mind and save yourself some sanity
      if (this.options == []) {
        return;
      }

      if (e.code == "ArrowUp") {
        if (this.currentItem > 0) {
          this.currentItem--;
        }
      } else if (e.code == "ArrowDown") {
        if (this.currentItem < this.options.length - 1) {
          this.currentItem++;
        }
      } else if (e.code == "Enter") {
        if (!this.hasSelected && this.options.length != 0 && this.open) {
          if (this.currentItem == -1) {
            this.currentItem = 0;
          }
          this.select(this.currentItem);
          this.$emit("pressedEnter");
        }
      } else if (e.code == "Tab") {
        this.$emit("continue");
      }
    },
    select(index) {
      this.hasSelected = true;
      this.$emit("selected", this.options[index]);
    },
    scrollCurrentIntoView() {
      this.$nextTick(function () {
        var els = document.getElementsByClassName("dropdown__item_current");

        if (els.length != 0) {
          els[0].scrollIntoView({
            block: "nearest",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  @include layer;

  position: absolute;
  width: 100%;
  border-radius: $border-radius-medium;
  display: none;
  z-index: 11;
  overflow: hidden;
  background-color: $c-white;

  &:focus-within,
  &:not(&_hidden) {
    display: block;
  }

  &__wrapper {}

  &__list {
    @include ul-unstyled;

    max-height: 50vh;
    overflow: auto;

    .dropdown_compact & {
      max-height: 15rem;
    }
  }

  &__item {
    padding: 0.65rem 0.75rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;

    .dropdown_compact & {
      padding: 0.5rem 0.75rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $c-lighter-gray;
    }

    &:focus,
    &:hover,
    &_current {
      outline: none;
      background-color: $c-blue-light;
    }
  }

  &__modes {
    display: flex;
    flex-direction: row;

    .modeicon {
      margin-right: 0.75rem;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0.75rem;

    &_other {
      background-image: url(../images/pin-black.svg);
      opacity: 0.33;
    }

    &_institution {
      background-image: url(../images/institution.svg);
    }

    &_shop {
      background-image: url(../images/shop.svg);
    }

    &_street {
      background-image: url(../images/street.svg);
    }

    &_stop {
      background-image: url(../images/origin-black.svg);
    }

    &_town {
      background-image: url(../images/building.svg);
    }

    &_building {
      background-image: url(../images/building.svg);
    }
  }

  &__titles {
    flex-grow: 1;
  }

  &__title {}

  &__subtitle {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    opacity: 0.55;
  }
}
</style>
