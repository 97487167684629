<template>
  <div class="page" @click="trigger('popupClose')">
    <div :class="[
      'page__side',
      !generalStore.sidebarOpen ? 'page__side_hidden' : '',
      sliding ? 'page__side_sliding' : '',
      touch ? 'page__side_touch' : '',
    ]" @touchstart.passive="touchStart($event)" @touchmove.passive="touchMove($event)" @touchend="touchEnd()"
      :style="[sidebarOffset != 0 ? { left: sidebarOffset + 'px' } : {}]">
      <div class="page__sidebar">
        <Sidebar :triggers="triggers" @beginGeolocation="beginGeolocation()" />
      </div>
      <div class="page__slider" @click="generalStore.toggleSidebar()">
        <div class="page__arrow">
          <img src="@/images/doublearrow-left-white.svg" alt="Toggle sidebar" />
        </div>
      </div>
    </div>
    <div class="page__map">
      <Map />
    </div>
    <div :class="['page__modal', modalOpen ? 'page__modal_open' : '']">
      <Modal />
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import Map from "./Map.vue";
import Modal from "./Modal.vue";

import "overlayscrollbars/css/OverlayScrollbars.css";
import OverlayScrollbars from "overlayscrollbars";

import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";
import Place from "@/core/Place";
import Coords from "@/core/Coords";

export default {
  name: "Page",
  components: {
    Sidebar,
    Map,
    Modal,
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  data() {
    return {
      // Sliding sidebar
      panStartX: 0,
      panStartY: 0,
      panCurrentX: 0,
      panCurrentY: 0,
      sidebarOffsetStart: 0,
      sidebarOffset: 0,
      sidebarWidth: 0,
      offsetX: 0,
      offsetY: 0,
      sliding: false,
      scrolling: false,
      touch: false,
      skipTouch: false,

      triggers: {
        popupClose: false,
      },
    };
  },
  computed: {
    modalOpen: function () {
      return this.generalStore.openModal != "";
    },
  },
  mounted() {
    OverlayScrollbars(document.getElementsByClassName("page__sidebar")[0], {
      scrollbars: {
        autoHide: "scroll",
        autoHideDelay: 500,
        dragScrolling: true,
        clickScrolling: true,
      },
    });
    this.checkPermissions();

    if (this.generalStore.supportsGeolocation && this.persistedStore.allowGeolocation) {
      this.beginGeolocation();
    }
  },
  methods: {
    trigger(t) {
      var newTriggers = JSON.parse(JSON.stringify(this.triggers));
      newTriggers[t] = !newTriggers[t];

      this.triggers = newTriggers;
    },
    touchStart(event) {
      let path;

      if (event.path) {
        path = event.path;
      } else {
        // Firefox compatibility
        path = event.composedPath();
      }

      var self = this;

      path.forEach(function (el) {
        if (el.classList && el.classList.contains("vue-slider")) {
          self.skipTouch = true;
        }
      });

      if (this.skipTouch) {
        return;
      }

      this.sidebarWidth =
        document.getElementsByClassName("page__sidebar")[0].width;
      this.sidebarOffsetStart =
        document.getElementsByClassName("page__side")[0].offsetLeft;
      this.panStartX = event.touches[0].clientX;
      this.panStartY = event.touches[0].clientY;
      this.touch = true;

      this.sidebarOffset = this.sidebarOffsetStart;
    },

    touchMove(event) {
      if (this.skipTouch) {
        return;
      }

      this.panCurrentX = event.touches[0].clientX;
      this.panCurrentY = event.touches[0].clientY;

      this.offsetX = this.panCurrentX - this.panStartX;
      this.offsetY = this.panCurrentY - this.panStartY;

      if (!this.sliding && !this.scrolling) {
        if (Math.abs(this.offsetY) < 20 && Math.abs(this.offsetX) > 20) {
          this.sliding = true;
        } else if (Math.abs(this.offsetY) > 20 && Math.abs(this.offsetX) < 20) {
          this.scrolling = true;
        }
      }

      if (this.sliding) {
        this.sidebarOffset = this.sidebarOffsetStart + this.offsetX;

        if (this.sidebarOffset < this.sidebarWidth) {
          this.sidebarOffset = this.sidebarWidth;
        } else if (this.sidebarOffset > 0) {
          this.sidebarOffset = this.sidebarWidth;
        }
      } else {
        this.sidebarOffset = this.sidebarOffsetStart;
      }
    },

    touchEnd() {
      if (this.skipTouch) {
        this.skipTouch = false;
        return;
      }

      if (this.offsetX < -30 && this.sliding) {
        this.generalStore.sidebarOpen = false;
      } else if (this.offsetX > 30 && this.sliding) {
        this.generalStore.sidebarOpen = true;
      }
      this.sidebarOffset = 0;
      this.sliding = false;
      this.scrolling = false;
      this.touch = false;
    },

    checkPermissions() {
      if (navigator.geolocation) {
        this.generalStore.supportsGeolocation = true;
      }
    },

    beginGeolocation() {
      window.addEventListener(
        "deviceorientationabsolute",
        (e) => {
          if (e.absolute) {
            this.generalStore.$patch({
              position: {
                rotation: e.alpha
              }
            });
          }
        },
        true
      );

      navigator.geolocation.watchPosition((position) => {
        // TODO are the following two lines necessary?
        this.persistedStore.allowGeolocation = true;

        this.generalStore.position = new Place({
          coords: new Coords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
          radius: position.coords.accuracy,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.page {
  font-family: $font-family;
  color: $c-font-black;
  display: flex;
  position: relative;
  height: 100vh;
  max-height: 100%;

  &_dark {
    color: $c-white;
  }

  &__side {
    z-index: 1;
    width: 385px;
    height: 100%;

    @media (max-width: $breakpoint-mobile) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      transition: left 0.35s ease-out;

      &_sliding,
      &_touch {
        transition: none;
      }

      &_hidden {
        left: calc(-100% + 30px);
      }
    }
  }

  &__sidebar {
    position: relative;
    height: calc(100vh - calc(100vh - 100%));
    width: 100%;
    overflow-y: auto;
    box-shadow: 0 0 1.5rem -0.05rem rgba($c-black, 0.35),
      0 0 0.25rem -0.05rem rgba($c-black, 0.45);
    background-color: $c-white;

    @media (max-width: $breakpoint-mobile) {
      width: calc(100% - 30px);
      box-shadow: 0 0 2.5rem 1.25rem rgba($c-black, 0.5);
    }
  }

  &__slider {
    display: none;
    position: absolute;
    left: calc(100% - 30px);
    width: 60px;
    height: 100%;

    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
  }

  &__arrow {
    position: relative;
    height: 100%;
    width: 30px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      width: 16px;
      left: 7px;
      transition: transform 0.35s ease-in-out;

      .page__side_hidden & {
        transform: rotate(180deg);
      }
    }
  }

  &__map {
    flex-grow: 1;
    z-index: 0;
    position: relative;
    touch-action: none;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;

    &_open {
      display: block;
    }
  }

  // OverlayScrollbars overrides
  $scrollbar-track-width: 0.5rem;
  $scrollbar-bar-width: 0.25rem;
  $scrollbar-track-padding: math.div($scrollbar-track-width - $scrollbar-bar-width,
      2);

  .os-scrollbar-vertical {
    width: $scrollbar-track-width !important;
    padding: $scrollbar-track-padding !important;
  }

  .os-scrollbar-handle {
    background: rgba($c-black, 0.15) !important;
    border-radius: 4px !important;
  }
}
</style>
