import { defineStore } from "pinia";
import Plan, { PlanPlaces } from "@/core/Plan";
import Place from "@/core/Place";
import Focus, { FocusType } from "@/core/Focus";
import dayjs from "dayjs";
import { createJSONFetch } from "../utils/fetch";
import config from "../../config";
import L from "leaflet/dist/leaflet";

// Path decoder
const pathDecoder = require("polyline-encoded");

export const useGeneralStore = defineStore("general", {
    state: () => {
        return {
            plan: null as Plan | null,
            action: "" as string,
            origin: null as Place | null,
            destination: null as Place | null,
            position: new Place() as Place | null,
            arriveBy: false as boolean,
            date: {
                value: "",
                date: dayjs(),
                name: {
                    primary: ""
                }
            } as any, // TODO temporary
            time: {
                hours: 0,
                minutes: 0,
                now: true
            } as any, // TODO temporary
            maxTransfers: 1 as number,
            maxWalkDistance: 0 as number,
            walkSpeed: 1 as number,
            optimize: "transfers" as string,
            focus: null as Focus | null,
            sidebarOpen: true as boolean,
            supportsGeolocation: false as boolean,
            openModal: "" as string,
        };
    },
    getters: {
        getPlanParameters(state) {
            return {
                origin: state.origin?.coords,
                destination: state.destination?.coords,
                arriveBy: state.arriveBy,
                date: state.date.value,
                maxTransfers: state.maxTransfers,
                maxWalkDistance: state.maxWalkDistance,
                walkSpeed: state.walkSpeed,
                optimize: state.optimize,
                time: state.time.now
                    ? dayjs().format("H:mm")
                    : `${state.time.hours}:${("0" + state.time.minutes).slice(-2)}`,
            };
        },
        getPlanPlaces(state): PlanPlaces {
            return {
                origin: state.origin,
                destination: state.destination
            }
        },
        getDestination(state): Place | null {
            return state.destination;
        }
    },
    actions: {
        openSidebar() {
            this.sidebarOpen = true;
        },
        closeSidebar() {
            this.sidebarOpen = false;
        },
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        generateOriginName() {
            this.origin?.generateName();
        },
        generateDestinationName() {
            this.destination?.generateName();
        },
        generatePositionName() {
            this.position?.generateName();
        },
        fetchPlan() {
            this.plan = null;
            this.focus = null;

            return createJSONFetch(config.api.host + "/plan", this.getPlanParameters)
                .then((response) => {
                    this.plan = new Plan(processPlan(response.plan));
                    this.focus = new Focus(FocusType.Itinerary, 0);
                });
        },
        swapPoints() {
            const temp = this.origin;
            this.origin = this.destination;
            this.destination = temp;
        },
    },
});

function processPlan(plan) {
    if (!plan.itineraries) {
        return plan;
    }

    plan.itineraries.forEach((itinerary) => {
        var legs = itinerary.legs;

        // Decode paths
        legs.forEach((leg) => {
            leg.decodedPath = pathDecoder.decode(leg.path);
        });

        // Extend walking paths
        legs.forEach((leg, index) => {
            if (leg.mode != "walk") {
                return;
            }

            if (index == 0) {
                leg.decodedPath.unshift(makeLatlng(leg.stops[0]));
            }

            if (index > 0) {
                let path = legs[index - 1].decodedPath;
                leg.decodedPath.unshift(path[path.length - 1]);
            }

            if (index < legs.length - 1) {
                let path = legs[index + 1].decodedPath;
                leg.decodedPath.push(path[0]);
            }

            if (index == legs.length - 1) {
                leg.decodedPath.push(makeLatlng(leg.stops[1]));
            }
        });

        // Get itinerary bounds
        itinerary.bounds = new L.latLngBounds();
        legs.forEach((leg) => {
            itinerary.bounds.extend(new L.latLngBounds(leg.decodedPath));
        });
    });

    return plan;
}

function makeLatlng(stop) {
    return [stop.coords.lat, stop.coords.lng];
}