export default {
    search: "Search",
    from: "Start",
    to: "Finish",
    your_location: "Your location",
    select_on_map: "Select on map",
    from_input: "From",
    to_input: "To",
    departure: "Departure",
    arrival: "Arrival",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    date_format: "DD/MM",
    now: "Now",
    more_settings: "Show advanced",
    fewer_settings: "Hide advanced",
    fewer_transfers: "Fewer transfers",
    short_duration: "Short duration",
    max_transfers: "Max. transfers:",
    max_walk_distance: "Max. walk distance:",
    walk_speed: "Walk speed:",
    stroll: "Stroll",
    walk: "Walk",
    trot: "Trot",
    sprint: "Sprint!",
    minute: "0 minutes | 1 minute | {n} minutes",
    minute_genitive: "@:minute",
    minute_ago: "now | 1 minute ago | {n} minutes ago",
    hour: "0 hours | 1 hour | {n} hours",
    intermediate_stop:
        "no intermediate stops | 1 intermediate stop | {n} intermediate stops",
    searching: "Searching...",
    departs_in: "departure in",
    walking: "Walking",
    waiting: "Waiting",
    pin: "Pin",
    unpin: "Unpin",
    show_on_map: "Show on map",
    finding_location: "Finding location...",
    select_origin: "Choose start by clicking anywhere on the map.",
    select_destination: "Choose finish by clicking anywhere on the map.",
    unpin_all: "Unpin all",
    favorite: "Favorite",
    recent_searches: "Recent searches",
    listening: "Listening...",
    sms_ticket: "SMS ticket",
    sms_ticket_content: `
        <p>
            <b>Before sending the message, verify that the information below is still valid.</b>
        </p>
        <p>
            <a class="modal__link" href="https://dpb.sk/sk/sms-listok">
                Information about SMS tickets at dpb.sk (in Slovak)
            </a>
        </p>
        <p>
            You can find the purchased travel ticket in the list of received messages
            on your device. Najdispoj is not responsible for the accuracy of the
            information stated on this page, which serves only as a simple redirect
            to a SMS app.
        </p>
    `,
    sms_send: "Send",
    sms_to_number: "Empty message to",
    data_sources: "Data sources",
    data_sources_content: "Timetables, information about vehicles, geodata:",
    allowgeo_text:
        "To show your location on the map, your permission is required.",
    allowgeo_button: "Grant access to location",
    zones: "Zones",
    price: "Price",
    validity: "Validity",
    about_content: `
        <p>
            Najdispoj is an <b>open source</b> project for creating and deploying
            a <b>modern public transport trip planner</b> freely and easily.
        </p>
        <p>
            Its goal is to <b>make public transport as accessible and attractive as possible</b>
            – so that <b>anyone can use it effortlessly</b>,
            including people without any previous knowledge of the local stops,
            or bus lines.
        </p>
        <p>
            The project is funded by its developers and maintained in their free time,
            because <b>we believe, that the public deserves such a service</b>.
        </p>
        <p>
            <a class="modal__link" href="https://www.facebook.com/najdispoj">facebook.com/najdispoj</a>
        </p>
        <p>
            <b>Source code</b><br>
            License
            <a class="modal__link" href="https://www.gnu.org/licenses/agpl-3.0.en.html" target="_blank">
            GNU Affero General Public License v3.0
            </a><br />
            Available at
            <a class="modal__link" href="https://gitlab.com/cstanislav/najdispoj" target="_blank">
            https://gitlab.com/cstanislav/najdispoj
            </a>
        </p>
    `,
    no_trips_pinned: "You haven't pinned any trips yet.",
    updated: "Updated",
    vehicle_no: "Vehicle no.",
    geolocation_failed: "We're unable to find your location.",
    no_results: "No trips were found.",
    auto: "Auto",
};
