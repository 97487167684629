import Stop, { StopJSON } from "./Stop";

export type LegJSON = {
  mode: string;
  duration: number;
  distance: number;
  path: string;
  decodedPath;
  departure: number;
  arrival: number;
  number: string;
  shortName: string;
  headsign: string;
  stops: Stop[];
  waitingDuration: number;
  timelineDuration: number | null;
  totalDuration: number;
  agencyId: string;
};

/**
 * Represents a segment of an itinerary.
 */
export default class Leg {
  readonly mode: string;
  readonly duration: number;
  readonly distance: number;
  readonly path: string;
  readonly decodedPath;
  readonly departure: number;
  readonly arrival: number;
  readonly number: string;
  readonly shortName: string;
  readonly headsign: string;
  readonly stops: StopJSON[];
  readonly waitingDuration: number;
  readonly timelineDuration: number | null;
  readonly totalDuration: number;
  readonly agencyId: string;

  constructor({
    mode,
    duration,
    distance,
    path,
    decodedPath,
    departure,
    arrival,
    number,
    shortName,
    headsign,
    stops,
    waitingDuration = 0,
    timelineDuration = null,
    totalDuration,
    agencyId,
  }: LegJSON) {
    this.mode = mode;
    this.duration = duration;
    this.distance = distance;
    this.path = path;
    this.decodedPath = decodedPath;
    this.departure = departure;
    this.arrival = arrival;
    this.number = number;
    this.shortName = shortName;
    this.headsign = headsign;
    this.stops = stops.map((stop) => new Stop(stop));
    this.waitingDuration = waitingDuration;
    this.timelineDuration = timelineDuration;
    this.totalDuration = totalDuration;
    this.agencyId = agencyId;
  }
}
