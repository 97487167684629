<template>
  <div class="modal" @click="closeModal()">
    <div class="modal__overlay">
      <div class="modal__container">
        <div class="modal__box" @click.stop="">
          <div class="modal__content" v-if="generalStore.openModal == 'about'">
            <div class="modal__heading">
              <img src="@/images/logo.svg" alt="Najdispoj.sk" />
            </div>
            <div class="modal__main" v-html="$t('about_content')"></div>
          </div>
          <div class="modal__content" v-if="generalStore.openModal == 'sources'">
            <div class="modal__heading">{{ $t("data_sources") }}</div>
            <div class="modal__main"> {{ $t('data_sources_content') }} <a v-for="source in sources" class='modal__link'
                :href='source.url'>{{ source.name }}</a>
            </div>
          </div>
          <div class="modal__content" v-if="generalStore.openModal == 'ticket'">
            <div class="modal__heading">{{ $t("sms_ticket") }}</div>
            <div class="modal__main" v-html="$t('sms_ticket_content')"></div>
            <div class="modal__tickets">
              <div class="modal__ticket">
                <a class="ticket" href="sms:1140">
                  <div class="ticket__heading"> {{ $t("sms_to_number") }} 1140 </div>
                  <div class="ticket__zones">
                    <div class="ticket__zones-label">{{ $t("zones") }}</div>
                    <div class="ticket__zones-value">100+101</div>
                  </div>
                  <div class="ticket__price">
                    <div class="ticket__price-label">{{ $t("price") }}</div>
                    <div class="ticket__price-value">1,00 €</div>
                  </div>
                  <div class="ticket__validity">
                    <div class="ticket__validity-label"> {{ $t("validity") }} </div>
                    <div class="ticket__validity-value"> {{ $t("minute", 40) }} </div>
                  </div>
                  <div class="ticket__button">{{ $t("sms_send") }}</div>
                </a>
              </div>
              <div class="modal__ticket">
                <a class="ticket" href="sms:1100">
                  <div class="ticket__heading"> {{ $t("sms_to_number") }} 1100 </div>
                  <div class="ticket__zones">
                    <div class="ticket__zones-label">{{ $t("zones") }}</div>
                    <div class="ticket__zones-value">100+101</div>
                  </div>
                  <div class="ticket__price">
                    <div class="ticket__price-label">{{ $t("price") }}</div>
                    <div class="ticket__price-value">1,30 €</div>
                  </div>
                  <div class="ticket__validity">
                    <div class="ticket__validity-label"> {{ $t("validity") }} </div>
                    <div class="ticket__validity-value"> {{ $t("minute", 70) }} </div>
                  </div>
                  <div class="ticket__button">{{ $t("sms_send") }}</div>
                </a>
              </div>
              <div class="modal__ticket">
                <a class="ticket" href="sms:1124">
                  <div class="ticket__heading"> {{ $t("sms_to_number") }} 1124 </div>
                  <div class="ticket__zones">
                    <div class="ticket__zones-label">{{ $t("zones") }}</div>
                    <div class="ticket__zones-value">100+101</div>
                  </div>
                  <div class="ticket__price">
                    <div class="ticket__price-label">{{ $t("price") }}</div>
                    <div class="ticket__price-value">4,50 €</div>
                  </div>
                  <div class="ticket__validity">
                    <div class="ticket__validity-label"> {{ $t("validity") }} </div>
                    <div class="ticket__validity-value"> {{ $t("hour", 24) }} </div>
                  </div>
                  <div class="ticket__button">{{ $t("sms_send") }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGeneralStore } from "@/stores/general";
import config from "../../config";

export default {
  name: "Modal",
  setup() {
    const generalStore = useGeneralStore();
    return { generalStore };
  },
  methods: {
    closeModal() {
      this.generalStore.openModal = "";
    },
  },
  computed: {
    sources: () => config.sources
  }
};
</script>

<style lang="scss">
.modal {
  display: block;
  height: 100%;

  &__overlay {
    background-color: rgba($c-black, 0.5);
    height: 100%;
    padding: 4rem 2rem;
  }

  &__container {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__box {
    @include layer;

    border-radius: $border-radius-medium;
    max-height: 100%;
    max-width: 640px;
    overflow: auto;
    // background-color: $c-white;
  }

  &__content {
    padding: 1.5rem;
    line-height: 1.35em;
  }

  &__heading {
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0.25rem 0 0.85rem;
  }

  &__link {
    @include a-unstyled($c-blue);

    font-weight: 700;
    display: block;
  }

  &__disclaimer {
    margin-bottom: 1.5rem;
  }

  &__tickets {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -0.5rem;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }
  }

  &__ticket {
    display: block;
    padding: 0 0.5rem;
    flex-shrink: 0;
    flex-basis: 33.33%;

    @media (max-width: 800px) {
      flex-basis: 50%;
      margin-bottom: 1rem;
    }

    @media (max-width: $breakpoint-mobile) {
      max-width: 60vw;
    }
  }
}

.ticket {
  @include a-unstyled;
  @include layer;

  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  padding: 0.65rem 0.75rem;
  width: 100%;

  &__heading {
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    border-bottom: 1px solid $c-border-gray;
    flex-basis: 100%;
  }

  &__zones {
    margin-bottom: 0.65rem;
    flex-grow: 1;
  }

  &__zones-label {
    font-size: 0.75rem;
    opacity: 0.75;
  }

  &__zones-value {
    font-weight: 600;
  }

  &__price {
    margin-bottom: 0.65rem;
  }

  &__price-label {
    font-size: 0.75rem;
    opacity: 0.75;
  }

  &__price-value {
    font-weight: 600;
  }

  &__validity {
    flex-basis: 100%;
    margin-bottom: 0.75rem;
  }

  &__validity-label {
    font-size: 0.75rem;
    opacity: 0.75;
  }

  &__validity-value {
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__button {
    @include button-secondary(false, $c-blue, $c-white);

    font-size: 0.85rem;
    font-weight: 700;
    text-align: center;
    flex-basis: 100%;
  }
}
</style>
