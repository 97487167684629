import Leg from "./Leg";

export type ItineraryJSON = {
  duration: number;
  departure: number;
  arrival: number;
  legs: Leg[];
  timelineDuration: number | null;
  bounds,
};

/**
 * Represents an itinerary in a plan (trip/search result).
 */
export default class Itinerary {
  readonly duration: number;
  readonly departure: number;
  readonly arrival: number;
  readonly legs: Leg[];
  readonly timelineDuration: number | null;
  readonly bounds;

  constructor({
    duration,
    departure,
    arrival,
    legs,
    timelineDuration = null,
    bounds,
  }: ItineraryJSON) {
    this.duration = duration;
    this.departure = departure;
    this.arrival = arrival;
    this.legs = legs.map((leg) => new Leg(leg));
    this.timelineDuration = timelineDuration;
    this.bounds = bounds;
  }
}
