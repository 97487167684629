<template>
  <div class="timeline">
    <div class="timeline__container" :style="{ width: itinerary.timelineDuration + '%' }">
      <div v-for="(leg, index) in itinerary.legs" :class="['timeline__leg', 'mode_' + leg.mode]" :key="index"
        :style="{ width: leg.timelineDuration + '%' }">
        <div v-if="leg.mode != 'WALK'" :class="['timeline__number']"> {{ leg.number }} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    itinerary: Object,
  },
};
</script>

<style lang="scss">
.timeline {
  width: 0;
  transition: width 0.5s ease-in-out;
  margin-top: .75rem;
  margin-bottom: .25rem;
  position: relative;
  z-index: 14;

  .itinerary_mounted & {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__leg {
    text-align: center;
    position: relative;
    border-radius: 100px;
    height: .9rem;

    &:not(&.mode_WALK) {
      min-width: 2.25rem;
    }

    &:not(:last-child) {
      margin-right: 2px;
    }

    &.mode_WALK {
      height: auto;

      &:after {
        content: "";
        display: block;
        width: 100%;
        top: 100%;
        height: 2px;
        background-image: url(../images/walk-bg.svg);
        background-size: contain;
      }
    }

    @include color-by-mode(&, "", background-color);
  }

  &__number {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-white;
    gap: .25rem;
    position: relative;
    height: 100%;

    &:before {
      content: "";
      display: block;
      width: 0.75em;
      height: 100%;
      position: relative;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @include background-image-by-mode("", &);
    }
  }
}
</style>
