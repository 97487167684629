<template>
  <div :class="['timepicker', open ? 'timepicker_open' : '']">
    <div class="timepicker__inputs">
      <div class="timepicker__hours">
        <div class="timepicker__button" @click="incHour()">+</div>
        <input
          class="timepicker__value timepicker__value_hours"
          type="number"
          v-model="hours"
          @blur="onBlurHour()"
          @focus="
            focusedHour = true;
            $event.target.select();
          "
        />
        <div class="timepicker__button" @click="decHour()">-</div>
      </div>
      <div class="timepicker__divider">:</div>
      <div class="timepicker__minutes">
        <div class="timepicker__button" @click="incMin()">+</div>
        <input
          class="timepicker__value timepicker__value_minutes"
          type="number"
          v-model="minutesZero"
          @blur="onBlurMin()"
          @focus="
            focusedMin = true;
            $event.target.select();
          "
        />
        <div class="timepicker__button" @click="decMin()">-</div>
      </div>
    </div>
    <div class="timepicker__bottom">
      <div class="timepicker__now" tabindex="0" @click.stop="onClickNow()">
        {{ $t("now") }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Time",
  props: ["open", "time", "triggers"],
  data() {
    return {
      hours: this.initHours(),
      minutes: this.initMinutes(),
      focusedHour: false,
      focusedMin: false,
    };
  },
  computed: {
    minutesZero: {
      get() {
        if (this.focusedMin) {
          return this.minutes;
        } else {
          return ("0" + this.minutes).slice(-2);
        }
      },
      set(value) {
        this.minutes = value;
      },
    },
  },
  watch: {
    triggers: {
      handler: function (newVal, oldVal) {
        if (newVal.popupClose != oldVal.popupClose && this.open) {
          if (this.dirty) {
            this.$emit("setTime", {
              hours: this.hours,
              minutes: this.minutes,
              now: false,
            });
          }
          this.$emit("close");
        }
      },
      deep: true,
    },
    open: function () {
      if (this.open) {
        this.hours = this.initHours();
        this.minutes = this.initMinutes();
        this.$nextTick(function () {
          // Wait until after watch fuctions had finished executing
          this.dirty = false;
        });
      }
    },
    minutes: function () {
      this.dirty = true;
      if (this.focusedMin && this.minutes.toString().length == 2) {
        this.$emit("setTime", {
          hours: this.hours,
          minutes: this.minutes,
          now: false,
        });
        this.$emit("close");
      }
    },
    hours: function () {
      this.dirty = true;
      if (this.focusedHour && this.hours.toString().length == 2) {
        document.getElementsByClassName("timepicker__value_minutes")[0].focus();
      }
    },
  },
  mounted() {
    this.minutesStep = 5;
  },
  methods: {
    initHours() {
      return this.time.now ? this.curHours() : this.time.hours;
    },
    initMinutes() {
      return this.time.now ? this.curMinutes() : this.time.minutes;
    },

    curHours() {
      return parseInt(dayjs().format("H"));
    },

    curMinutes() {
      return parseInt(dayjs().format("m"));
    },

    incHour() {
      this.hours++;
      if (this.hours > 23) {
        this.hours = 0;
      }
    },
    decHour() {
      this.hours--;
      if (this.hours < 0) {
        this.hours = 23;
      }
    },
    incMin() {
      this.minutes =
        Math.floor(this.minutes / this.minutesStep) * this.minutesStep;
      this.minutes += this.minutesStep;

      if (this.minutes > 59) {
        this.minutes = 0;
      }
    },
    decMin() {
      this.minutes =
        Math.ceil(this.minutes / this.minutesStep) * this.minutesStep;
      this.minutes -= this.minutesStep;

      if (this.minutes < 0) {
        this.minutes = 60 - this.minutesStep;
      }
    },

    onBlurHour() {
      this.focusedHour = false;

      if (this.hours > 23) {
        this.hours = 23;
      }
      if (this.hours < 0) {
        this.hours = 0;
      }
    },

    onBlurMin() {
      this.focusedMin = false;

      if (this.minutes > 59) {
        this.minutes = 59;
      }
      if (this.minutes < 0) {
        this.minutes = 0;
      }
    },

    onClickNow() {
      this.$emit("setTime", {
        hours: this.curHours(),
        minutes: this.curMinutes(),
        now: true,
      });
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.timepicker {
  @include layer;

  position: absolute;
  top: -3rem;
  width: 100%;
  display: none;
  justify-content: center;

  :not(.page_dark) & {
    background-color: $c-white;
  }

  .page_dark & {
    background-color: $c-dark-gray;
  }

  &_open {
    display: block;
  }

  &__inputs {
    display: flex;
    padding: 0.75rem;
    border-bottom: 1px solid $c-light-gray;

    .page_dark & {
      border-color: $c-black;
    }
  }

  &__hours {
    flex-grow: 1;
  }

  &__divider {
    display: flex;
    align-items: center;
    width: 0.5rem;
    justify-content: center;
  }

  &__minutes {
    flex-grow: 1;
  }

  &__button {
    appearance: none;
    border: none;
    background: none;
    padding: 0.25rem 0.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: $border-radius-medium;
    width: 100%;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
      background-color: $c-light-gray;
    }

    .page_dark & {
      color: rgba($c-white, 0.4);
      background-color: $c-black;
    }
  }

  &__value {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    border: none;
    background: none;
    width: 100%;
    font-weight: 600;
    font-size: 1.15rem;
    text-align: center;
    padding: 0.5rem 0.25rem;

    &::-webkit-inner-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .page_dark & {
      color: rgba($c-white, 0.75);
    }
  }

  &__bottom {
    padding: 0.75rem;
  }

  &__now {
    border-radius: $border-radius-medium;
    text-align: center;
    padding: 0.75rem 0.5rem;
    font-weight: 600;
    cursor: pointer;

    :not(.page_dark) & {
      color: rgba($c-font-black, 0.55);
      background-color: $c-light-gray;
    }

    .page_dark & {
      color: rgba($c-white, 0.4);
      background-color: $c-black;
    }
  }
}
</style>
