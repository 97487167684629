<template>
  <div class="options">
    <div class="options__header">
      <Header></Header>
    </div>
    <div class="options__tabs">
      <div :class="[
        'options__tab',
        activeTab == 'search' ? 'options__tab_active' : '',
      ]" @click="selectTab('search')">
        <div class="options__tab-label">🔍</div>
        <div class="options__tab-name">{{ $t("search") }}</div>
      </div>

      <div :class="[
        'options__tab',
        activeTab == 'saved' ? 'options__tab_active' : '',
      ]" @click="selectTab('saved')">
        <div class="options__tab-label">
          📌
          <div class="options__num" v-if="persistedStore.savedItineraries.length != 0">
            {{ persistedStore.savedItineraries.length }}
          </div>
        </div>
        <!-- <div class="options__tab-name">Pripnuté</div> -->
      </div>
      <!-- <div class="options__tab">
        <div class="options__tab-label">❓</div>
        <div class="options__tab-name">Pomocník</div>
      </div> -->
    </div>
    <div class="options__pages">
      <div :class="[
        'options__page',
        activeTab == 'search' ? 'options__page_active' : '',
      ]">
        <div class="options__filter">
          <div class="options__category">
            <div class="options__points">
              <div class="options__point options__point_origin">
                <div class="options__label">
                  <div class="options__icon">
                    <Icon file="origin-black.svg" />
                  </div>
                  <div class="options__name">{{ $t("from") }}</div>
                </div>
                <div class="options__content">
                  <Point :isOrigin="true" :point="this.generalStore.origin" @updatePoint="generalStore.origin = $event"
                    @pressedEnter="focusDestination()"
                    @selectFromMap="generalStore.closeSidebar(); generalStore.action = 'selectOriginFromMap'" />
                </div>
              </div>

              <div class="options__swap">
                <div class="options__swap-wrapper">
                  <div class="options__swap-button" @click="generalStore.swapPoints" tabindex="0">
                    <Icon file="swap-black.svg" />
                  </div>
                </div>
              </div>

              <div class="options__point options__point_destination">
                <div class="options__label">
                  <div class="options__icon">
                    <Icon file="destination-black.svg" />
                  </div>
                  <div class="options__name">{{ $t("to") }}</div>
                </div>
                <div class="options__content">
                  <Point :isOrigin="false" :point="this.generalStore.destination"
                    @updatePoint="generalStore.destination = $event" @pressedEnter="focusDatetime()"
                    @selectFromMap="generalStore.closeSidebar(); generalStore.action = 'selectDestinationFromMap'" />
                </div>
              </div>
            </div>
          </div>
          <div class="options__category">
            <!-- <div class="options__label"></div> -->
            <div class="options__content">
              <Datetime :triggers="triggers" />
            </div>
          </div>
        </div>
        <Advanced />
        <!-- <div class="options__chart">📊 Graf</div> -->
        <Plan />

        <Recent />
      </div>
      <div :class="[
        'options__page',
        activeTab == 'saved' ? 'options__page_active' : '',
      ]">
        <Saved />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import Point from "./Point.vue";
import Datetime from "./Datetime.vue";
import Advanced from "./Advanced.vue";
import Saved from "./Saved.vue";
import Plan from "./Plan.vue";
import Recent from "./Recent.vue";
import Header from "./Header.vue";

import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  components: {
    Header,
    Icon,
    Point,
    Datetime,
    Advanced,
    Saved,
    Plan,
    Recent,
  },
  props: {
    state: Object,
    triggers: Object,
  },
  data() {
    return {
      options: {},
      activeTab: "search",
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  methods: {
    selectTab(id) {
      this.activeTab = id;
    },
    focusDestination() {
      document.getElementsByClassName("point__input")[1].focus();
    },
    focusDatetime() {
      document.getElementsByClassName("datetime__arriveby")[0].focus();
    },
  },
};
</script>

<style lang="scss">
.options {
  &__header {
    // Cover up the shadow from __tabs
    background-color: $c-white;
    position: relative;
    z-index: 20;
  }

  &__tabs {
    @include layer;
    border-radius: 0;

    display: flex;
    position: sticky;
    top: 0;
    z-index: 16;

    padding: 0 1rem;
    column-gap: 1rem;
  }

  &__tab {
    text-align: center;
    padding: 1rem 0 0.75rem;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:after {
      content: "";
      display: block;
      height: 0;
      bottom: -1px;
      position: absolute;
      width: calc(100%);
      border-radius: 3px;
      transition: height 0.05s ease-in-out;
      background-color: $c-primary;
    }

    &_active {
      position: relative;

      &:after {
        height: 3px;
      }
    }
  }

  &__tab-name {
    opacity: 0.65;
    transition: opacity 0.2s ease-in-out;

    .options__tab:not(.options__tab_active):hover & {
      opacity: 0.85;
    }

    .options__tab_active & {
      opacity: 1;
    }
  }

  &__tab-label {
    opacity: 0.65;
    transition: opacity 0.2s ease-in-out;
    position: relative;

    .options__tab:not(.options__tab_active):hover & {
      opacity: 0.85;
    }

    .options__tab_active & {
      opacity: 1;
    }
  }

  &__num {
    position: absolute;
    margin-left: 0.15rem;
    bottom: -0.35rem;
    right: -0.1rem;
    overflow: hidden;
    font-size: 0.77rem;
    // background-color: rgb(12, 12, 12);
    font-weight: 800;
    pointer-events: none;
    color: $c-red;
  }

  &__page {
    display: none;

    &_active {
      display: block;
    }
  }

  &__filter {
    margin: 1rem;
    position: relative;
    z-index: 15;
  }

  &__category {
    display: flex;
    align-items: center;
  }

  &__points {
    width: 100%;
  }

  &__point {
    display: flex;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 10%;
    padding-top: 0.35rem;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__icon {
    margin-bottom: 0.35rem;

    img {
      width: 1.65rem;

      .page_dark .options__point_destination & {
        filter: invert(1) brightness(0.5);
      }
    }
  }

  &__name {
    font-size: 0.75rem;
    font-weight: 600;
    opacity: 0.75;
    line-height: 1em;
  }

  &__content {
    flex-grow: 1;
  }

  &__swap {
    position: relative;
    display: flex;
  }

  &__swap-wrapper {
    // similar to options__label for now
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 10%;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__swap-button {
    @include button-secondary;

    flex-basis: 10%;
    flex-shrink: 0;
    position: absolute;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    border-radius: 100px;
    top: -3.25rem;
    cursor: pointer;

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }

    img {
      width: 0.85rem;
      opacity: 0.8;
    }
  }

  &__divider {
    $divider-size: 1.25rem;

    background-color: $c-border-gray;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    margin-top: -1.5px;
    z-index: 100;

    &:before,
    &:after {
      top: -1rem;
      transition: top 0.2s ease-in-out;
    }

    &_active {

      &:before,
      &:after {
        top: 0;
      }
    }
  }
}
</style>
