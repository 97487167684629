<template>
  <div class="map">
    <div class="map__element" id="map"></div>
    <div v-if="hint != ''" class="map__hint-container">
      <div class="map__hint"> {{ hint }} </div>
    </div>
    <div class="map__hidden" id="hidden-popup"></div>
    <Popup :target="popupTarget" @selectOrigin="selectOrigin" @selectDestination="selectDestination" />
    <div class="map__vehicle-tooltip" ref="vehiclePopup">
      <VehiclePopup :vehicle="focusedVehicle" />
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
import VehiclePopup from "./VehiclePopup.vue";
import L from "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";
import config from "../../config";
import throttle from "lodash.throttle";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";
import { useMapObjectStore } from "@/stores/mapObject";
import Coords from "../core/Coords";
import Place from "../core/Place";

export default {
  name: "Map",
  components: {
    Popup,
    VehiclePopup
  },
  data() {
    return {
      popupTarget: "#hidden-popup",
      focusedVehicle: null,
      canFireEvent: true,
      moving: false, // Is the map moving? (e.g. after changing focus, zooming)
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    const mapObjectStore = useMapObjectStore();
    const persistedStore = usePersistedStore();

    return { generalStore, mapObjectStore, persistedStore };
  },
  watch: {
    "generalStore.position": {
      handler() {
        this.drawPosition();
      },
      deep: true
    },
    "generalStore.focus": {
      handler(newFocus, oldFocus) {
        if (JSON.stringify(newFocus) != JSON.stringify(oldFocus)) {
          this.changeFocus();
        }
      },
      deep: true
    },
    "generalStore.origin": function () {
      if (this.generalStore.origin && this.generalStore.origin.coords) {
        this.originMarker.setLatLng(this.generalStore.origin.coords);
        this.originMarker.addTo(this.map);
      } else {
        this.originMarker.remove();
        this.resetItinerary();
      }
    },
    "generalStore.destination": function () {
      if (this.generalStore.destination && this.generalStore.destination.coords) {
        this.destinationMarker.setLatLng(this.generalStore.destination.coords);
        this.destinationMarker.addTo(this.map);
      } else {
        this.destinationMarker.remove();
        this.resetItinerary();
      }
    }
  },
  computed: {
    hint: function () {
      if (this.generalStore.action == "selectOriginFromMap") {
        return this.$t("select_origin");
      } else if (this.generalStore.action == "selectDestinationFromMap") {
        return this.$t("select_destination");
      } else {
        return "";
      }
    },
  },
  mounted() {
    dayjs.extend(utc);

    this.initMap();
    this.initVehicles();
  },
  methods: {
    initIcons() {
      this.icons = {
        originIcon: L.icon({
          iconUrl: require("@/images/origin-black-bordered.svg"),
          iconSize: [42, 42],
          iconAnchor: [6, 42],
        }),
        destinationIcon: L.icon({
          iconUrl: require("@/images/destination-black-bordered.svg"),
          iconSize: [42, 42],
          iconAnchor: [6, 42],
        }),
        stopPrimaryIcon: L.icon({
          iconUrl: require("@/images/stop-primary.svg"),
          iconSize: [12, 12],
        }),
        stopSecondaryIcon: L.icon({
          iconUrl: require("@/images/stop-secondary.svg"),
          iconSize: [11, 11],
        }),
      };
    },
    initMarkers() {
      var self = this;

      this.originMarker = L.marker([0, 0], {
        icon: this.icons.originIcon,
        draggable: true,
        autoPan: true,
      }).bindTooltip(`<div class="map__popup">${this.$t("from")}</div>`, {
        direction: "bottom",
        opacity: 1,
      });
      this.originMarker.on("dragend", () => {
        this.stopEvents();
        this.generalStore.origin = new Place({
          coords: new Coords(this.originMarker.getLatLng())
        });
        this.generalStore.generateOriginName();
      });

      this.destinationMarker = L.marker([0, 0], {
        icon: this.icons.destinationIcon,
        draggable: true,
        autoPan: true,
      }).bindTooltip(`<div class="map__popup">${this.$t("to")}</div>`, {
        direction: "bottom",
        opacity: 1,
      });
      this.destinationMarker.on("dragend", () => {
        this.stopEvents();
        this.generalStore.destination = new Place({
          coords: new Coords(this.destinationMarker.getLatLng())
        });
        this.generalStore.generateDestinationName();
      });
      this.positionRadius = L.circle([0, 0], {
        radius: 0,
        weight: 1,
        opacity: 0.5,
        color: "#077fcf",
        fillColor: "#077fcf",
        fillOpacity: 0.25,
      });
    },

    drawVehicles() {
      function getItineraryLegVehicleInfo(itinerary) {
        // Returns an array of objects containing pairs of routeShortName and agencyId
        // used when checking whether a vehicle is part of the selected itinerary
        let items = [];

        itinerary.legs.forEach((leg) => {
          if (leg.number) {
            items.push([leg.number, leg.agencyId]);
          }
        });

        return items;
      }

      this.mapObjectStore.vehicles.forEach((vehicle) => {
        if (!this.map.getBounds().pad(0.1).contains(vehicle.coords)) {
          // If marker is not visible on current map view
          return;
        }

        const focus = this.generalStore.focus;

        if (
          // Plan exists and an itinerary is selected
          this.generalStore.plan &&
          !this.generalStore.plan.isEmpty() &&
          focus.itinerary != null && this.generalStore.plan.itineraries[focus.itinerary]
        ) {
          if ( // If selected itinerary doesn't include the current line number
            !getItineraryLegVehicleInfo(this.generalStore.plan.itineraries[focus.itinerary]).some(
              (item) =>
                JSON.stringify(item) == JSON.stringify([vehicle.routeShortName, vehicle.agencyId])
            )
          ) {
            return;
          }
        } else if (navigator.userAgentData?.mobile && this.map.getZoom() < 14) {
          // User is on mobile and map is zoomed out
          return;
        } else if (this.map.getZoom() < 12) {
          // User is not on mobile and map is even more zoomed out
          return;
        }

        let rotation = 45 + vehicle.bearing;
        let type = vehicle.type ? " vehicle-icon_type_" + vehicle.type : "";

        var marker = L.marker(vehicle.coords, {
          icon: L.divIcon({
            iconAnchor: [0, 0],
            html: `
                <div class='vehicle-icon${type}'>
                  <div
                    class='vehicle-icon__marker'
                    style='transform: rotate(${rotation}deg)'
                  >
                  </div>
                  <div class='vehicle-icon__line'>
                    ${vehicle.routeShortName ? vehicle.routeShortName : "?"}
                  </div>
                </div>
              `,
          }),
        }).bindTooltip(() => {
          return this.$refs.vehiclePopup;
        }, {
          direction: "top",
          opacity: 1,
        }).addTo(this.map);

        marker.on("mouseover", (e) => {
          this.focusedVehicle = vehicle;

          this.$nextTick(() => {
            // Adjust width after DOM is loaded
            marker.getTooltip().update();
          });
        });

        marker.on("mouseout", (e) => {
          this.unfocusVehicle();
        });

        // TODO replace vehicle.number with an identifier that differentiates between agencies
        this.vehicleMarkers.push([vehicle.number, marker]);
      });
    },

    removeVehicles() {
      if (this.vehicleMarkers) {
        this.vehicleMarkers.forEach(([vehicleId, marker]) => {
          marker.remove();
        });
      }
      this.vehicleMarkers = [];
    },

    refreshVehiclePopup() {
      if (this.focusedVehicle) {
        this.vehicleMarkers.forEach(([vehicleId, marker]) => {
          if (vehicleId == this.focusedVehicle.number) {
            marker.openTooltip();
          }
        });
      }
    },

    unfocusVehicle() {
      this.focusedVehicle = null;
      this.vehicleMarkers.forEach(([vehicleId, marker]) => {
        marker.closeTooltip();
      });
    },

    initVehicles() {
      if (!config.map.vehicles) {
        return;
      }

      this.vehicleMarkers = [];

      this.throttledDrawVehicles = throttle(() => {
        this.removeVehicles();
        this.drawVehicles();
        this.refreshVehiclePopup();
      }, 200);

      this.fetchVehicles();

      this.map.on("moveend", () => {
        this.throttledDrawVehicles();

        if (!this.moving && this.generalStore.focus) {
          this.generalStore.focus.hasMoved = true;
        }
      });
      this.map.on("zoomend", this.throttledDrawVehicles);
    },

    fetchVehicles() {
      this.mapObjectStore.fetchVehicles(this.throttledDrawVehicles);
      setTimeout(this.fetchVehicles, 5000);
    },

    initMap() {
      this.map = L.map("map", {
        zoomSnap: 0,
        zoomControl: false,
      });
      this.map.attributionControl.setPrefix(
        '<a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>'
      );
      this.map.setView(
        [config.map.center.lat, config.map.center.lng],
        config.map.zoom
      );

      // Controls
      new L.Control.Zoom({
        position: "bottomright",
      }).addTo(this.map);
      new L.Control.Scale({
        maxWidth: 150,
        imperial: false,
      }).addTo(this.map);

      this.map.on("click", this.onClick);
      this.map.on("dblclick", this.onDblclick);

      // TODO check licenses and add more tile layers from:
      // https://leaflet-extras.github.io/leaflet-providers/preview/

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        // detectRetina: true
      }).addTo(this.map);

      // Popup
      this.popup = L.popup({});
      this.vehiclePopup = L.popup({});

      // Icons
      this.initIcons();

      // Markers
      this.initMarkers();

      // Colors
      this.colors = {
        WALK: "#4caf50",
        BUS: "#077fcf",
        COACH: "#077fcf",
        TROLLEYBUS: "#ffae00",
        TRAM: "#f50101",
        RAIL: "#ff671f",
        SUBWAY: "#00ab4f",
        FERRY: "#311fff",
        OTHER: "#202020",
      };
    },

    resetItinerary() {
      if (this.polylines) {
        this.polylines.forEach(function (p) {
          p.remove();
        });
      }

      if (this.whitePolylines) {
        this.whitePolylines.forEach(function (p) {
          p.remove();
        });
      }

      if (this.markers) {
        this.markers.forEach(function (m) {
          m.remove();
        });
      }

      this.polylines = [];
      this.whitePolylines = [];
      this.markers = [];
    },

    makeLatlng(stop) {
      return [stop.coords.lat, stop.coords.lng];
    },

    displayItinerary(itinerary) {
      this.resetItinerary();

      // Draw polylines
      itinerary.legs.forEach((leg) => {
        leg.bounds = new L.latLngBounds(leg.decodedPath);

        var polyline;
        polyline = L.polyline(leg.decodedPath, {
          color: "#ffffff",
          weight: 12,
          dashArray: leg.mode == "WALK" ? "0 12" : null,
        })
          .bindTooltip(`<div class="map__popup">${leg.mode == "WALK" ? this.$t("walking") : leg.number}</div>`, {
            direction: "top",
            sticky: true,
            opacity: 1,
          })
          .addTo(this.map);

        this.whitePolylines.push(polyline);

        polyline = L.polyline(leg.decodedPath, {
          color: this.colors[leg.mode],
          weight: 8,
          dashArray: leg.mode == "WALK" ? "0 12" : null,
          interactive: false,
        }).addTo(this.map);

        this.polylines.push(polyline);
      });

      // Draw stops
      itinerary.legs.forEach((leg) => {
        if (leg.mode != "WALK") {
          leg.stops.forEach((s, index) => {
            var primary = index == 0 || index == leg.stops.length - 1;

            var coords;
            if (index == 0) {
              coords = leg.decodedPath[0];
            } else if (index == leg.stops.length - 1) {
              coords = leg.decodedPath[leg.decodedPath.length - 1];
            } else {
              coords = this.makeLatlng(s);
            }

            var marker = L.marker(coords, {
              icon: primary
                ? this.icons.stopPrimaryIcon
                : this.icons.stopSecondaryIcon,
            })
              .bindTooltip(`<div class="map__popup">${s.name}</div>`, {
                direction: "top",
                opacity: 1,
              })
              .addTo(this.map);

            this.markers.push(marker);
          });
        }
      });
    },

    changeFocus() {
      const focus = this.generalStore.focus;

      if (
        focus?.savedItineraries && this.persistedStore.savedItineraries
      ) {
        this.focusItinerary();

        if (focus.itinerary < this.persistedStore.savedItineraries.length) {
          this.displayItinerary(this.persistedStore.savedItineraries[focus.itinerary]);
        }
      } else if (
        this.generalStore.plan &&
        !this.generalStore.plan.isEmpty()
      ) {
        this.focusItinerary();
        this.displayItinerary(this.generalStore.plan.itineraries[focus.itinerary]);
      } else if (
        !this.generalStore.plan ||
        this.generalStore.plan.isEmpty()
      ) {
        // If there's no plan, remove itinerary from map
        this.resetItinerary();
      }
    },

    flyToBounds(bounds) {
      this.moving = true;
      this.map.flyToBounds(bounds, {
        padding: [50, 50],
        duration: 0.75,
      });
      setTimeout(
        () => {
          this.moving = false;
        },
        750
      )
    },

    focusItinerary() {
      // Pan to itinerary/leg
      var bounds, b;
      const focus = this.generalStore.focus;
      if (focus.itinerary == null) {
        return;
      }

      if (focus.leg == null) {
        // Pan to itinerary
        if (focus.savedItineraries) {
          b = this.persistedStore.savedItineraries[focus.itinerary].bounds;
          bounds = L.latLngBounds(b._northEast, b._southWest);
        } else {
          bounds = this.generalStore.plan.itineraries[focus.itinerary].bounds;
        }

        this.flyToBounds(bounds);
      } else {
        // Pan to leg
        if (focus.savedItineraries) {
          bounds = L.latLngBounds(
            this.persistedStore.savedItineraries[
              focus.itinerary
            ].legs[focus.leg].decodedPath
          );
        } else {
          bounds = L.latLngBounds(
            this.generalStore.plan.itineraries[
              focus.itinerary
            ].legs[focus.leg].decodedPath
          );
        }

        this.flyToBounds(bounds);
      }
    },

    onClick(e) {
      if (!this.canFireEvent) {
        return;
      }

      this.unfocusVehicle();
      this.stopEvents();

      var self = this;

      setTimeout(() => {
        if (!self.canFireEvent) {
          return;
        }

        if (self.generalStore.action == "selectOriginFromMap") {
          this.generalStore.origin = new Place({
            coords: new Coords(e.latlng)
          });
          this.generalStore.generateOriginName();
          this.generalStore.openSidebar();
          self.generalStore.action = "";

          return;
        } else if (self.generalStore.action == "selectDestinationFromMap") {
          this.generalStore.destination = new Place({
            coords: new Coords(e.latlng)
          });
          this.generalStore.generateDestinationName();
          this.generalStore.openSidebar();
          self.generalStore.action = "";

          return;
        }
        self.popupTarget = ".leaflet-popup-content";
        self.popupCoords = e.latlng;
        self.popup.setLatLng(e.latlng).openOn(self.map);

        self.$nextTick(() => {
          // Adjust width after DOM is loaded
          self.popup.update();
        });
      }, 201);
    },

    onDblclick() {
      this.stopEvents();
    },

    selectOrigin() {
      this.generalStore.origin = new Place({
        coords: new Coords({
          lat: this.popupCoords.lat,
          lng: this.popupCoords.lng,
        })
      });
      this.generalStore.generateOriginName();
      this.map.closePopup();
    },
    selectDestination() {
      this.generalStore.destination = new Place({
        coords: new Coords({
          lat: this.popupCoords.lat,
          lng: this.popupCoords.lng,
        })
      });
      this.generalStore.generateDestinationName();
      this.map.closePopup();
    },
    stopEvents() {
      var self = this;

      self.canFireEvent = false;

      clearTimeout(self.timeout);

      this.timeout = setTimeout(function () {
        self.canFireEvent = true;
      }, 200);
    },
    drawPosition() {
      // Position
      if (
        this.generalStore.position.coords &&
        this.generalStore.position.radius < 200
      ) {
        this.positionRadius.setLatLng(this.generalStore.position.coords);
        this.positionRadius.setRadius(this.generalStore.position.radius);
        this.positionRadius.addTo(this.map);

        if (this.positionMarker) {
          this.positionMarker.remove();
        }

        let rotation = 45 - this.generalStore.position.rotation;
        this.positionMarker = L.marker([0, 0], {
          icon: L.divIcon({
            iconAnchor: [0, 0],
            html: `
                <div class='position-icon'>
                  <div
                    class='position-icon__marker'
                    style='transform: rotate(${rotation}deg)'
                  ></div>
                  <div class='position-icon__symbol'></div>
                </div>
              `,
          }),
        }).bindTooltip(`<div class="map__popup">${this.$t("your_location")}</div>`, {
          direction: "bottom",
          opacity: 1,
        });
        this.positionMarker.setLatLng(this.generalStore.position.coords);
        this.positionMarker.addTo(this.map);
      } else {
        this.positionRadius.remove();
      }

      if (config.map.vehicles) {
        this.throttledDrawVehicles();
      }
    },
  },
};
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
  position: relative;

  &__hint-container {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  &__hint {
    @include layer;

    padding: 1rem 1.25rem;
  }

  &__element {
    height: 100%;
    z-index: 0;

    :not(.page_dark) & {
      background-color: #f2efe9;
    }

    .page_dark & {
      background-color: $c-dark-gray;
    }
  }

  &__hidden {
    display: none;
  }
}

.leaflet-container {
  font-family: $font-family;
}

.leaflet-tile-pane {

  // Experimental dark mode
  .page_dark & {
    filter: invert(1) hue-rotate(200deg) grayscale(0.45);
  }
}

.leaflet-overlay-pane {
  filter: drop-shadow(0 2px 0 darken($c-white, 12)) drop-shadow(0 2px 2px rgba($c-black, 0.35));
}

.leaflet-marker-icon {
  border: none;
}

.leaflet-div-icon {
  background: none;
  width: 0 !important;
  height: 0 !important;
}

.leaflet-tooltip {
  border-radius: $border-radius-medium;
  border: none;
  padding: 0;
  box-shadow: none;
  box-shadow: 0 2px 0 darken($c-white, 12), 0 2px 2px rgba($c-black, 0.35);
}

.leaflet-popup-content-wrapper {
  padding: 0;
  z-index: -1;
  position: relative;

  .page_plastic & {
    border-bottom: 2px solid $c-light-gray-side;
  }

  &:after {
    $arrow-size: 12px;

    content: "";
    display: block;
    position: absolute;
    top: calc(100% - 1px);
    left: calc(50% - #{$arrow-size});
    border-top: $arrow-size solid $c-white;
    border-left: $arrow-size solid transparent;
    border-right: $arrow-size solid transparent;
    border-top-color: $c-white;
  }
}

.leaflet-popup-content {
  margin: 0;
  width: auto !important;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem !important;
  height: 1.75rem !important;
  border-radius: 100px;
  padding: 0.15rem !important;
  top: -0.6rem !important;
  right: -0.6rem !important;
  font-size: 1rem !important;

  :not(.page_dark) & {
    color: rgba($c-black, 0.55) !important;
    background-color: $c-white !important;
  }

  .page_dark & {
    color: rgba($c-white, 0.55) !important;
    background-color: $c-black !important;
  }
}

.vehicle-icon {
  position: relative;
  border: 1px solid $c-white;
  display: flex;
  justify-content: center;
  align-items: center;

  &.vehicle-icon_type_bus {
    filter: drop-shadow(0 2px 0 darken($c-mode-bus, 12));
  }

  &.vehicle-icon_type_trolley {
    filter: drop-shadow(0 2px 0 darken($c-mode-trolley, 12));
  }

  &.vehicle-icon_type_tram {
    filter: drop-shadow(0 2px 0 darken($c-mode-tram, 12));
  }

  &.vehicle-icon_type_train {
    filter: drop-shadow(0 2px 0 darken($c-mode-train, 12));
  }

  &__marker {
    position: absolute;
    width: 1.85rem;
    height: 1.85rem;
    border-radius: 50%;
    border-top-left-radius: 2px;
    background-color: $c-gray;

    .vehicle-icon_type_bus & {
      background-color: $c-mode-bus;
    }

    .vehicle-icon_type_trolley & {
      background-color: $c-mode-trolley;
    }

    .vehicle-icon_type_tram & {
      background-color: $c-mode-tram;
    }

    .vehicle-icon_type_train & {
      background-color: $c-mode-train;
    }
  }

  &__line {
    color: $c-white;
    position: absolute;
    font-size: 0.75rem;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.position-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 2px 0 darken($c-mode-walk, 12));

  &__marker {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border-top-left-radius: 2px;
    background-color: $c-mode-walk;
  }

  &__symbol {
    position: absolute;
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 50%;
    background-color: darken($c-mode-walk, 12);
  }
}
</style>
