<template>
  <div class="datetime">
    <div class="datetime__field">
      <div class="datetime__arriveby" tabindex="0" @click="toggleArriveBy" @focus="arrivebyFocus = true"
        @blur="arrivebyFocus = false"> {{ typeString }} </div>
    </div>
    <div class="datetime__field">
      <div class="datetime__date" tabindex="0" @focus="dateFocus = true" @blur="dateFocus = false"> {{ dateString }}
      </div>
      <div class="datetime__dropdown">
        <Dropdown @selected="updateDate" @pressedEnter="focusTime()" :options="this.dates" :open="this.dateFocus"
          compact />
      </div>
    </div>
    <div class="datetime__field">
      <div class="datetime__time">
        <div class="datetime__time-value"> {{ timeString }} </div>
        <div class="datetime__timepicker" @click.stop="timeOpen">
          <Timepicker :time="generalStore.time" :open="timeFocus" :triggers="triggers" @setTime="updateTime"
            @close="timeClose()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import Timepicker from "./Timepicker.vue";
import dayjs from "dayjs";
import { useGeneralStore } from "@/stores/general"

export default {
  name: "Datetime",
  components: {
    Dropdown,
    Timepicker,
  },
  props: {
    triggers: Object,
  },
  data() {
    return {
      arrivebyFocus: false,
      dateFocus: false,
      timeFocus: false,
      today: dayjs(),
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    return { generalStore };
  },
  mounted() {
    var self = this;
    document.addEventListener("keydown", function (e) {
      if (e.code == "Space" && self.arrivebyFocus) {
        e.preventDefault();
        self.toggleArriveBy();
      }
    });
    this.popupItem = this.$el;

    this.generalStore.date = this.dates[1];
  },
  computed: {
    dates: function () {
      var dates = [];

      for (var i = -1; i < 7; i++) {
        var date = this.today.add(i, "day");

        var value = date.format("YYYY-MM-DD");
        var name = this.formatDate(date);

        dates.push({
          value: value,
          date: date,
          name: {
            primary: name,
          },
        });
      }

      return dates;
    },
    timeString: function () {
      if (this.generalStore.time.now && this.isToday()) {
        return this.$t("now");
      } else {
        return `${this.generalStore.time.hours}:${("0" + this.generalStore.time.minutes).slice(-2)}`;
      }
    },
    dateString: function () {
      return this.generalStore.date.name.primary;
    },
    typeString: function () {
      return this.generalStore.arriveBy ? this.$t("arrival") : this.$t("departure");
    },
  },
  watch: {
    time: function (newVal) { // TODO ????????
      if (newVal != "") {
        this.generalStore.time = this.time;
      }
    },
  },
  methods: {
    isToday() {
      return this.generalStore.date.date.diff(this.today, "day") == 0;
    },
    toggleArriveBy() {
      this.generalStore.arriveBy = !this.generalStore.arriveBy;
    },
    updateTime(time) {
      this.generalStore.time = time;
    },
    updateDate(date) {
      this.generalStore.date = date;
    },
    focusTime() {
      this.timeOpen();
      this.$nextTick(function () {
        document.getElementsByClassName("timepicker__value_hours")[0].focus();
      });
    },
    formatDate(date) {
      var diff = date.diff(this.today, "day");

      if (diff == -1) {
        return this.$t("yesterday");
      }

      if (diff == 0) {
        return this.$t("today");
      }

      if (diff == 1) {
        return this.$t("tomorrow");
      }

      // TODO use UpdateLocale plugin?
      // https://day.js.org/docs/en/customization/weekday-abbreviations
      var localWeekdays = {
        Mon: this.$t("mon"),
        Tue: this.$t("tue"),
        Wed: this.$t("wed"),
        Thu: this.$t("thu"),
        Fri: this.$t("fri"),
        Sat: this.$t("sat"),
        Sun: this.$t("sun"),
      };
      return (
        localWeekdays[date.format("ddd")] +
        " " +
        date.format(this.$t("date_format"))
      );
    },

    timeOpen() {
      this.timeFocus = true;
    },

    timeClose() {
      this.timeFocus = false;
    },

    focusHour() {
      document.getElementsByClassName("timepicker__value_hours")[0].focus();
    },
  },
};
</script>

<style lang="scss">
.datetime {
  display: flex;
  margin: 0 -0.25rem;

  &__type {}

  &__field {
    flex-grow: 1;
    flex-basis: 100%;
    padding: 0 0.25rem;
    position: relative;
  }

  &__arriveby {
    @include crater($c-light-gray);

    display: flex;
    align-items: center;
    cursor: pointer;
    padding: .65rem .5rem;
    width: 100%;
    border-radius: $border-radius-medium;
    font-size: 1rem;
    font-weight: 600;
    color: rgba($c-font-black, 0.55);

    .page_plastic & {
      border-bottom: 2px solid $c-light-gray-side;
    }
  }

  &__date {
    @include crater($c-light-gray);

    width: 100%;
    height: 100%;
    border-radius: $border-radius-medium;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.65rem .5rem;
    color: rgba($c-font-black, 0.55);

    option {
      margin: 0.25rem;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
  }

  &__time {
    position: relative;
  }

  &__time-value {
    @include crater($c-light-gray);

    padding: 0.65rem .5rem;
    border-radius: $border-radius-medium;
    font-weight: 600;
    color: rgba($c-font-black, 0.55);
  }

  &__timepicker {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 600;
    color: rgba($c-font-black, 0.55);
  }
}
</style>
