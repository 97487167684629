<template>
  <teleport :to="target" v-if="isMounted">
    <div class="popup">
      <div class="popup__buttons">
        <div class="popup__button" @click="$emit('selectOrigin')" tabindex="0">
          <div class="popup__icon popup__icon_origin"></div>
          <div class="popup__name">{{ $t("from") }}</div>
        </div>
        <div class="popup__button" @click="$emit('selectDestination')" tabindex="0">
          <div class="popup__icon popup__icon_destination"></div>
          <div class="popup__name">{{ $t("to") }}</div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "Popup",
  emits: ["selectOrigin", "selectDestination"],
  props: ["target"],
  data: function () {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
.popup {
  border-radius: $border-radius-medium;

  :not(.page_dark) & {
    background-color: $c-white;
  }

  .page_dark & {
    background-color: $c-black;
  }

  &__buttons {
    display: flex;
    padding: 0.65rem;
  }

  &__button {
    @include layer;

    padding: 0.35rem 0.5rem;
    border-radius: $border-radius-medium;
    width: 4.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.65rem;
    }
  }

  &__icon {
    width: 1.85rem;
    height: 1.85rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 0.5rem;
    margin-bottom: 0.35rem;

    &_origin {
      background-image: url(../images/origin-black.svg);
    }

    &_destination {
      background-image: url(../images/destination-black.svg);

      .page_dark & {
        filter: invert(1) brightness(0.5);
      }
    }
  }

  &__name {
    font-size: 0.75rem;
    font-weight: 600;
    opacity: 0.75;

    :not(.page_dark) & {
      color: $c-font-black;
    }

    .page_dark & {
      color: rgba($c-white, 0.85);
    }
  }
}
</style>
