export default {
    search: "Vyhľadávač",
    from: "Štart",
    to: "Cieľ",
    your_location: "Vaša poloha",
    select_on_map: "Označiť na mape",
    from_input: "Odkiaľ cestujete?",
    to_input: "Kam cestujete?",
    departure: "Odchod",
    arrival: "Príchod",
    today: "Dnes",
    yesterday: "Včera",
    tomorrow: "Zajtra",
    mon: "Pon",
    tue: "Uto",
    wed: "Str",
    thu: "Štv",
    fri: "Pia",
    sat: "Sob",
    sun: "Ned",
    date_format: "D.M.",
    now: "Teraz",
    more_settings: "Viac nastavení",
    fewer_settings: "Menej nastavení",
    fewer_transfers: "Menej prestupov",
    short_duration: "Krátke trvanie",
    max_transfers: "Max. počet prestupov:",
    max_walk_distance: "Max. pešia vzdialenosť:",
    walk_speed: "Rýchlosť chôdze:",
    stroll: "Prechádzka",
    walk: "Chôdza",
    trot: "Poklus",
    sprint: "Šprint!",
    minute: "0 minút | 1 minúta | {n} minúty | {n} minút",
    minute_genitive: "0 minút | 1 minútu | {n} minúty | {n} minút",
    minute_ago: "teraz | pred 1 minútou | pred {n} minútami | pred {n} minútami",
    hour: "0 hodín | 1 hodina | {n} hodiny | {n} hodín",
    intermediate_stop:
        "0 medzizastávok | 1 medzizastávka | {n} medzizastávky | {n} medzizastávok",
    searching: "Hľadám spoje...",
    departs_in: "odchod o",
    walking: "Peší presun",
    waiting: "Čakanie",
    pin: "Pripnúť",
    unpin: "Odopnúť",
    show_on_map: "Zobraziť na mape",
    finding_location: "Zisťujem polohu...",
    select_origin: "Zvoľte štart kliknutím na ľubovoľné miesto na mape.",
    select_destination: "Zvoľte cieľ kliknutím na ľubovoľné miesto na mape.",
    unpin_all: "Odopnúť všetky",
    favorite: "Obľúbené",
    recent_searches: "Posledné hľadané",
    listening: "Počúvam...",
    sms_ticket: "SMS lístok",
    sms_ticket_content: `
        <p>
            <b>Pred odoslaním SMS si na nasledujúcom odkaze overte, či sú nižšie
            uvedené informácie aktuálne platné.</b>
        </p>
        <p>
            <a class="modal__link" href="https://dpb.sk/sk/sms-listok">
                Aktuálne informácie o SMS lístkoch na dpb.sk
            </a>
        </p>
        <p>
            Zakúpený cestovný lístok nájdete v zozname prijatých SMS správ na
            Vašom zariadení. Najdispoj nezodpovedá za správnosť údajov
            uvedených na tejto stránke, ktorá slúži iba ako jednoduché
            presmerovanie do SMS aplikácie.
        </p>
    `,
    sms_send: "Poslať SMS",
    sms_to_number: "Prázdna SMS na číslo",
    data_sources: "Zdroje dát",
    data_sources_content: "Cestovné poriadky, informácie o vozidlách, geografické dáta:",
    allowgeo_text:
        "Pre zobrazenie vašej polohy na mape je potrebné vaše povolenie.",
    allowgeo_button: "Povoliť prístup k polohe",
    zones: "Zóny",
    price: "Cena",
    validity: "Platnosť",
    about_content: `
        <p>
            Najdispoj je <b>open source</b> projekt umožňujúci jednoducho
            a bezplatne vytvoriť a nasadiť do prevádzky <b>moderný vyhľadávač dopravných
            spojov</b>.
        </p>
        <p>
            Za cieľ si kladie <b>zjednodušiť orientáciu vo verejnej doprave</b>
            na absolútne minimum – <b>tak, aby ju dokázal využiť úplne každý</b>,
            a to vrátane ľudí bez akýchkoľvek znalostí miestnych zastávok,
            či dopravných liniek.
        </p>
        <p>
            Projekt je financovaný z vlastného vrecka a vyvíjaný vo voľnom čase,
            pretože <b>veríme, že verejnosť si podobnú službu zaslúži</b>.
        </p>
        <p>
            <a class="modal__link" href="https://www.facebook.com/najdispoj">facebook.com/najdispoj</a>
        </p>
        <p>
            <b>Zdrojový kód</b><br>
            Licencia
            <a class="modal__link" href="https://www.gnu.org/licenses/agpl-3.0.en.html" target="_blank">
            GNU Affero General Public License v3.0
            </a><br />
            Dostupný na
            <a class="modal__link" href="https://gitlab.com/cstanislav/najdispoj" target="_blank">
            https://gitlab.com/cstanislav/najdispoj
            </a>
        </p>
    `,
    no_trips_pinned: "Momentálne nemáte pripnuté žiadne spoje.",
    updated: "Aktualizované",
    vehicle_no: "Vozidlo č.",
    geolocation_failed: "Nepodarilo sa zistiť vašu polohu.",
    no_results: "Zadaniu nevyhovujú žiadne spoje.",
    auto: "Automaticky",
};
