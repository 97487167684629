<template>
  <div class="recent">
    <div class="recent__box" v-if="persistedStore.favoritePlans.length > 0">
      <div class="recent__head">⭐ {{ $t("favorite") }}</div>
      <div class="recent__body">
        <div class="recent__item" v-for="(recent, index) in persistedStore.favoritePlans" :key="index"
          @click="loadFavorite(index)">
          <div class="recent__label">
            <div class="recent__point recent__point_origin">
              {{ recent.origin.name.primary }}
            </div>
            <div class="recent__arrow">→</div>
            <div class="recent__point recent__point_destination">
              {{ recent.destination.name.primary }}
            </div>
          </div>
          <div :class="[
            'recent__favorite',
            isFavorite(recent) ? 'recent__favorite_true' : '',
          ]" @click.stop="toggleFavorite(recent)"></div>
        </div>
      </div>
    </div>

    <div class="recent__box" v-if="persistedStore.recentPlans.length > 0">
      <div class="recent__head">🕑 {{ $t("recent_searches") }}</div>
      <div class="recent__body">
        <div class="recent__item" v-for="(recent, index) in persistedStore.recentPlans.slice().reverse()" :key="index"
          @click="loadRecent(persistedStore.recentPlans.length - index - 1)">
          <div class="recent__label">
            <div class="recent__point recent__point_origin">
              {{ recent.origin.name.primary }}
            </div>
            <div class="recent__arrow">→</div>
            <div class="recent__point recent__point_destination">
              {{ recent.destination.name.primary }}
            </div>
          </div>
          <div :class="[
            'recent__favorite',
            isFavorite(recent) ? 'recent__favorite_true' : '',
          ]" @click.stop="toggleFavorite(recent)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  name: "Recent",
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  methods: {
    loadRecent(index) {
      var params = this.persistedStore.recentPlans[index];
      this.generalStore.origin = params.origin;
      this.generalStore.destination = params.destination;
    },
    loadFavorite(index) {
      var params = this.persistedStore.favoritePlans[index];
      this.generalStore.origin = params.origin;
      this.generalStore.destination = params.destination;
    },
    toggleFavorite(recent) {
      this.persistedStore.toggleFavoritePlan(recent);
    },
    isFavorite(recent) {
      var stringified = JSON.stringify(recent);
      var favorite = false;
      this.persistedStore.favoritePlans.forEach(function (plan) {
        if (stringified == JSON.stringify(plan)) {
          favorite = true;
          return;
        }
      });

      return favorite;
    },
  },
};
</script>

<style lang="scss">
.recent {
  margin: 1rem 1rem 0;
  background-color: $c-white;

  &__box {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__head {
    // margin-bottom: 0.25rem;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0.75rem 0;
  }

  &__body {
    @include layer;
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.15rem 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $c-light-gray;
    }
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    padding: 0.35rem 0;
  }

  &__point {
    font-weight: 600;
    font-size: 0.85rem;

    .recent__label:hover & {
      text-decoration: underline;
    }
  }

  &__arrow {
    opacity: 0.25;
    margin: 0 0.35rem;
  }

  &__favorite {
    margin-left: 1rem;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/favorite.svg);

    &:not(&_true) {
      opacity: 0.45;
      filter: grayscale(100%);
    }

    &_true {
      background-image: url(../images/favorited.svg);
      opacity: 1;
    }
  }
}
</style>
