<template>
  <div class="sidebar">
    <div class="sidebar__options">
      <Options :triggers="triggers" />
    </div>
    <div class="sidebar__content">
      <div class="sidebar__allowgeo" v-if="generalStore.supportsGeolocation && !persistedStore.allowGeolocation">
        {{ $t("allowgeo_text") }}
        <div class="sidebar__allowgeo-button" @click="allowGeolocation()">
          ✅ {{ $t("allowgeo_button") }}
        </div>
      </div>
    </div>
    <div class="sidebar__footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Options from "./Options.vue";
import Footer from "./Footer.vue";
import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  components: {
    Options,
    Footer,
  },
  props: {
    triggers: Object,
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  data() {
    return {
      height: window.innerHeight,
    };
  },
  mounted() {
    window.onresize = () => {
      this.height = window.innerHeight;
    };
  },
  methods: {
    allowGeolocation() {
      this.$emit("beginGeolocation");
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  :not(.page_dark) & {
    background-color: $c-white;
  }

  .page_dark & {
    background-color: $c-black;
  }

  input {
    font-family: inherit;
  }

  .page__body_dark & {
    background-color: $c-black;
  }

  &__options {
    z-index: 10;
  }

  &__content {
    flex-grow: 1;
    margin: 1rem;
    z-index: 5;

    :not(.page_dark) & {
      background-color: $c-white;
    }

    .page_dark & {
      background-color: $c-dark-gray;
    }
  }

  &__allowgeo {
    @include layer;

    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    text-align: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :not(.page_dark) & {
      background-color: $c-white;
    }

    .page_dark & {
      background-color: $c-dark-gray;
    }
  }

  &__allowgeo-button {
    @include button-secondary(false, $c-green-light, $c-green);

    margin-top: 1rem;
  }

  &__recent {
    flex-grow: 1;
  }
}
</style>
