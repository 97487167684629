import Coords from "./Coords";

export type StopJSON = {
  name: string;
  coords: Coords;
  departure: number;
};

/**
 * Represents a stop marker that's shown on the map.
 */
export default class Stop {
  readonly name: string;
  readonly coords: Coords;
  readonly departure: number;

  constructor({ name, coords, departure }: StopJSON) {
    this.name = name;
    this.coords = coords;
    this.departure = departure;
  }
}
