<template>
  <div class="plan">
    <div class="plan__head">
      <div v-if="loading" class="plan__loading"> {{ $t("searching") }} </div>
      <div v-if="generalStore.plan?.isEmpty()" class="plan__empty"> {{ $t("no_results") }} </div>
    </div>
    <div v-if="!loading" class="plan__body">
      <Itinerary v-if="generalStore.plan && !generalStore.plan.isEmpty()"
        v-for="(itinerary, index) in generalStore.plan.itineraries" :itineraryIndex="index" :key="index"
        :itinerary="itinerary" :focused="focused(index)" @click="focusItinerary(index)"
        @focusItinerary="(legIndex) => this.focusItinerary(index, legIndex)" />
    </div>
  </div>
</template>

<script>
import Itinerary from "./Itinerary.vue";
import Focus, { FocusType } from "@/core/Focus";
import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";

export default {
  name: "Plan",
  components: {
    Itinerary,
  },

  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();

    return { generalStore, persistedStore };
  },
  watch: {
    "generalStore.getPlanParameters": function () {
      this.generalStore.plan = null;

      // Origin or destination is not set - clear plan
      if (
        !this.generalStore.getPlanParameters.origin ||
        !this.generalStore.getPlanParameters.destination
      ) {
        return;
      }

      this.loading = true;
      this.generalStore.fetchPlan().then((response) => {
        this.loading = false;
      });
    },
    "generalStore.getPlanPlaces": {
      handler: function () {
        if (
          this.generalStore.origin?.coords &&
          this.generalStore.origin?.name &&
          this.generalStore.destination?.coords &&
          this.generalStore.destination?.name
        ) {
          this.persistedStore.toggleRememberPlan(this.generalStore.getPlanPlaces);
        }
      },
      deep: true,
    }
  },
  methods: {
    focusItinerary(itineraryIndex, legIndex = null) {
      this.generalStore.focus = new Focus(
        FocusType.Itinerary,
        itineraryIndex,
        false,
        legIndex
      );
    },
    focused(index) {
      return this.generalStore.focus?.savedItineraries == false && index == this.generalStore.focus?.itinerary;
    }
  },
  created() {
    this.planParameters = this.generalStore.getPlanParameters;
  },
};
</script>

<style lang="scss">
.plan {
  height: 100%;
  margin: 1rem 0;

  &__head {
    font-size: 1.1rem;
    font-weight: 600;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 1rem 0;

    &:before {
      content: "";
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../images/loading.svg);
      margin-right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;

      // Spinning animation
      animation-name: spin;
      animation-duration: 0.35s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__empty {
    text-align: center;
    padding: 1rem 0;
  }
}
</style>
