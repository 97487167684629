<template>
  <div class="footer">
    <div class="footer__menu">
      <div class="footer__row">
        <div v-if="ticket" class="footer__item">
          <div class="footer__button" @click="openModal('ticket')">
            🎫 {{ $t("sms_ticket") }}
          </div>
        </div>
      </div>
      <div class="footer__row">
        <div class="footer__item">
          <a class="footer__link" @click="openModal('about')"> Najdispoj </a>
        </div>
        <div class="footer__item">
          <a class="footer__link" @click="openModal('sources')">
            {{ $t("data_sources") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGeneralStore } from "@/stores/general";
import config from "../../config";

export default {
  name: "Footer",
  setup() {
    const generalStore = useGeneralStore();
    return { generalStore };
  },
  computed: {
    ticket: function () {
      return config.ticket;
    }
  },
  methods: {
    openModal(modal) {
      this.generalStore.openModal = modal;
    },
  },
};
</script>

<style lang="scss">
.footer {
  @include layer;
  border-radius: 0;

  padding: 1rem;
  background-color: $c-white;

  &__menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__row {
    display: flex;

    // width: 100%;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $c-font-black;

    &+& {
      &:before {
        content: "·";
        display: block;
        margin: 0 0.5rem;
        font-size: 1em;
      }
    }
  }

  &__button {
    @include button-secondary(false, $c-blue, $c-white);
  }

  &__link {
    @include a-unstyled();

    cursor: pointer;
    border-radius: $border-radius-medium;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
