<template>
  <div :class="[
    'itinerary',
    mounted ? 'itinerary_mounted' : '',
    open ? 'itinerary_open' : '',
    focused ? 'itinerary_focused' : '',
  ]" @click="toggleOpen()">
    <div class="itinerary__head">
      <div class="itinerary__time">
        <div class="itinerary__timespan">
          <div class="itinerary__time-from"> {{ timeFormat(itinerary.departure) }} </div>
          <div class="itinerary__time-to"> {{ timeFormat(itinerary.arrival) }} </div>
        </div>
        <div class="itinerary__time-duration"> {{ $t("minute", Math.ceil(itinerary.duration / 60)) }} </div>
      </div>
      <div v-show="departureIn != ''" class="itinerary__departure"> {{ departureIn }} </div>
      <Timeline :itinerary="itinerary" />
    </div>
    <div class="itinerary__body">
      <div class="itinerary__legs">
        <Leg v-for="(leg, index) in itinerary.legs" :leg="leg" :key="index" @click="onLegClick(index)" />
      </div>
      <div class="itinerary__buttons">
        <a v-if="!isSaved" class="itinerary__button itinerary__button_pin"
          @click.stop="this.persistedStore.saveItinerary(itinerary)"> 📌<sub>+</sub></a>
        <a v-if="isSaved" class="itinerary__button itinerary__button_unpin"
          @click.stop="this.persistedStore.unsaveItinerary(itinerary)"> 📌<sub>-</sub></a>
        <div class="itinerary__button itinerary__button_map" @click.stop="focusItinerary()"> 🗺️ {{ $t("show_on_map") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Leg from "./Leg.vue";
import Focus, { FocusType } from "@/core/Focus"
import Timeline from "./Timeline.vue";
import { useGeneralStore } from "@/stores/general";
import { usePersistedStore } from "@/stores/persisted";
import isEqual from "lodash.isequal";

export default {
  name: "Itinerary",
  components: {
    Leg,
    Timeline,
  },
  props: {
    focused: {
      type: Boolean,
      default: false,
    },
    itinerary: Object,
    itineraryIndex: Number,
  },
  data() {
    return {
      mounted: false,
      open: false,
      now: dayjs(),
      departureIn: this.getDepartureIn(),
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    const persistedStore = usePersistedStore();
    return { generalStore, persistedStore };
  },
  watch: {
    focused: function (isFocused) {
      if (isFocused) {
        this.open = true;
      }
    },
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
      this.$emit("focus");
    },
    timeFormat(timestamp) {
      return dayjs(timestamp).format("H:mm");
    },
    focusItinerary() {
      this.$emit("focusItinerary");
      this.generalStore.closeSidebar();
    },
    onLegClick(legIndex) {
      this.$emit("focusItinerary", legIndex);
    },
    getDepartureIn() {
      var difference = Math.floor(
        (this.itinerary.departure - dayjs().valueOf()) / 1000
      );
      if (difference > 86400) {
        // > 24 hours
        return "odchod " + dayjs(this.itinerary.departure).format("D.M.YYYY");
      }
      if (difference > 3600) {
        // > 1 hour
        var hours = Math.floor(difference / 3600);
        var seconds = difference % 3600;
        return (
          this.$t("departs_in") +
          " " +
          hours +
          " hod. " +
          Math.floor(seconds / 60) +
          " min. "
        );
      }
      const duration = Math.ceil(difference / 60); // Convert to minutes
      return difference > 0
        ? this.$t("departs_in") + " " + this.$t("minute_genitive", duration)
        : "";
    },
  },
  computed: {
    isSaved: function () {
      var isSaved = false;
      this.persistedStore.savedItineraries.forEach((itinerary) => {
        if (isEqual(itinerary, this.itinerary)) {
          isSaved = true;
          return;
        }
      });
      return isSaved;
    },
  },
  mounted() {
    this.mounted = true;

    if (this.focused) {
      this.open = true;
    }

    var self = this;
    this.timer = setInterval(function () {
      self.departureIn = self.getDepartureIn();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.itinerary {
  position: relative;
  background-color: $c-white;
  overflow: hidden;
  // transition: box-shadow 0.2s ease-in-out;

  border-top: 1px solid $c-light-gray;

  &:last-child {
    border-bottom: 1px solid $c-light-gray;
  }

  &_focused {
    @include crater(rgba($c-light-gray, .2));
    border-left: 4px solid $c-blue;
    border-radius: 0;
  }

  &:not(&_open) {
    cursor: pointer;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 1rem 0;
    margin-bottom: -.4rem; // Half of timeline__leg height
  }

  &__timespan {
    display: flex;
    font-weight: 700;
    font-size: 1.15rem;
  }

  &__time-from {
    display: flex;

    &:after {
      content: "-";
      display: block;
      margin: 0 0.25rem;
    }
  }

  &__time-to {
    margin-right: 0.25rem;
  }

  &__time-duration {
    opacity: 0.45;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    color: $c-black;

    &_good {
      color: $c-green;
    }

    &_bad {
      color: $c-red;
    }

    &:before {
      content: "";
      display: block;
      width: 0.85rem;
      height: 0.85rem;
      margin-right: 0.35rem;
      background-image: url(../images/clock.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__departure {
    margin-top: 0.15rem;
    opacity: 0.65;
    font-size: 0.85rem;
  }

  &__ratings {
    flex-basis: 45%;
    font-size: 0.85rem;
  }

  &__rating {
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 0.15rem;
    }

    &_good {
      color: $c-green;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        background-image: url(../images/check.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 0.25rem;
      }
    }
  }

  &__body {
    .itinerary_open & {
      padding-bottom: 0;
    }
  }

  &__legs {
    pointer-events: none;
    padding: 1rem 1rem 1rem 0;
    border-radius: $border-radius-medium;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;

    .itinerary_open & {
      pointer-events: auto;
    }
  }

  &__buttons {
    display: none;
    justify-content: flex-end;
    margin: 0 1rem 1rem;

    :not(.page_dark) & {
      border-top-color: $c-light-gray;
    }

    .page_dark & {
      border-top-color: $c-black;
    }

    .itinerary_open & {
      display: flex;
    }
  }

  &__button {
    &_pin {
      @include button-secondary(false, $c-green, $c-white);
    }

    &_unpin {
      @include button-secondary(false, $c-red, $c-white);
    }

    &_map {
      @include button-secondary(false, $c-blue, $c-white);
    }

    border-radius: $border-radius-medium;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    // font-size: .9rem !important;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    sub {
      position: absolute;
      width: 0.5em;
      height: 0;
      transform: translate(-0.5em, 0.5em);
      font-size: 1em;
      opacity: 0.75;
    }
  }
}
</style>
