import Coords, { CoordsJSON } from "./Coords";
import { createJSONFetch } from "../utils/fetch";
import config from "../../config";

export type PlaceName = {
  primary: string;
  secondary: string;
};

export type PlaceJSON = {
  name?: PlaceName;
  coords?: CoordsJSON;
  type?: string;
  radius?: number;
  rotation?: number; // rotation: 0, // 0 = North, - = clockwise direction
  loading?: boolean;
};

export default class Place {
  name?: PlaceName;
  readonly coords?: Coords;
  readonly type?: string;
  readonly radius?: number;
  readonly rotation?: number;
  loading: boolean; // Should be read-only from outside, rw from inside 

  constructor({
    name = undefined,
    coords = undefined,
    type = undefined,
    radius = undefined,
    rotation = undefined,
    loading = false,
  }: PlaceJSON = {}) {
    this.name = name;
    this.coords = coords !== undefined ? new Coords(coords) : undefined;
    this.type = type;
    this.radius = radius;
    this.rotation = rotation;
    this.loading = loading;
  }

  public async generateName() {
    this.loading = true;
    return Place.reverseGeocode(this.coords).then((place) => {
      this.name = place.name;
      this.loading = false;
    });
  }

  public static async reverseGeocode(coords) {
    let name: PlaceName = { primary: "Unknown", secondary: "Unknown" };
    return createJSONFetch(config.api.host + "/reverse", {
      coords: coords,
    });
  };
}
