export type CoordsJSON = {
  lat: number;
  lng: number;
};

/**
 * Represents a (latitude, longitude) pair.
 */
export default class Coords {
  readonly lat: number;
  readonly lng: number;

  constructor({ lat, lng }: CoordsJSON) {
    this.lat = lat;
    this.lng = lng;
  }
}
