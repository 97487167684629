import Coords, { CoordsJSON } from "./Coords";

export enum VehicleType {
  Bus = "bus",
  Trolley = "trolley",
  Tram = "tram",
  Train = "train",
  Subway = "subway",
  Unknown = "unknown",
}

export type VehicleMap = Map<number, Vehicle>;

export type VehicleJSON = {
  agencyId: string | null;
  number: number | null;
  routeShortName: string | null;
  routeLongName: string | null;
  coords: CoordsJSON | null;
  bearing: number | null;
  type: VehicleType | null;
  lastUpdate: number | null;
};

/**
 * Represents a vehicle marker on the map.
 */
export default class Vehicle {
  readonly agencyId: string | null;
  readonly number: number | null;
  readonly routeShortName: string | null;
  readonly routeLongName: string | null;
  readonly coords: CoordsJSON | null;
  readonly bearing: number | null;
  readonly type: VehicleType | null;
  readonly lastUpdate: number | null;

  readonly json;

  constructor(json: VehicleJSON) {
    this.agencyId = json.agencyId;
    this.number = json.number;
    this.routeShortName = json.routeShortName;
    this.routeLongName = json.routeLongName;
    this.coords = json.coords ? new Coords(json.coords) : null;
    this.bearing = json.bearing;
    this.type = json.type;
    this.lastUpdate = json.lastUpdate;

    this.json = json;
  }
}
