<template>
  <div :class="['leg', 'mode_' + leg.mode]" @click.stop="toggleOpen()">
    <div class="leg__label">
      <ModeIcon :mode="leg.mode" />
      <div v-if="leg.number" class="leg__number"> {{ leg.number }} </div>
    </div>
    <div v-if="leg.mode == 'WALK'" class="leg__info">
      <div class="leg__name"> {{ $t("walking") }} <span class="leg__distance">({{ distanceFormat(leg.distance)
      }})</span>
      </div>
      <div class="leg__duration"> {{ $t("minute", durationFormat(leg.duration)) }} </div>
      <div v-if="leg.waitingDuration" class="leg__waiting"> {{ $t("waiting") }} {{ $t("minute_genitive",
        durationFormat(leg.waitingDuration))
      }} </div>
    </div>
    <div v-else class="leg__details">
      <div class="leg__head">
        <div v-if="leg.shortName" class="leg__short-name">{{ leg.shortName }}</div>
        <div v-if="leg.headsign" class="leg__headsign">{{ leg.headsign }}</div>
      </div>
      <div class="leg__stop">
        <div class="leg__stop-name">{{ leg.stops[0].name }}</div>
        <div class="leg__stop-time leg__stop-time_departure"> {{ timeFormat(leg.stops[0].departure) }} </div>
      </div>
      <Intermediate :open="this.intermediateOpen" :stops="leg.stops" />
      <div class="leg__stop">
        <div class="leg__stop-name"> {{ leg.stops[leg.stops.length - 1].name }} </div>
        <div class="leg__stop-time"> {{ timeFormat(leg.stops[leg.stops.length - 1].departure) }} </div>
      </div>
      <div v-if="leg.waitingDuration" class="leg__waiting"> {{ $t("waiting") }} {{ $t("minute_genitive",
        durationFormat(leg.duration, false))
      }} </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Intermediate from "./Intermediate.vue";
import ModeIcon from "./ModeIcon.vue";
import { useGeneralStore } from "@/stores/general";

export default {
  name: "Leg",
  components: {
    Intermediate,
    ModeIcon,
  },
  props: {
    leg: Object,
  },
  data() {
    return {
      intermediateOpen: false,
    };
  },
  setup() {
    const generalStore = useGeneralStore();
    return { generalStore };
  },
  methods: {
    toggleOpen() {
      this.intermediateOpen = !this.intermediateOpen;
      if (this.leg.mode == "WALK") {
        this.generalStore.closeSidebar();
      }
    },
    timeFormat(timestamp) {
      return dayjs(timestamp).format("H:mm");
    },
    distanceFormat(distance) {
      if (distance < 1000) {
        return Math.floor(distance) + " m";
      } else {
        return Math.floor(distance / 100) / 10 + " km";
      }
    },
    durationFormat(duration, roundUp = true) {
      if (roundUp) {
        duration = Math.ceil(duration / 60); // Round up just to be sure
      } else {
        duration = Math.floor(duration / 60);
      }

      if (duration == 0) {
        duration = 1;
      }

      return duration;
    },
  },
};
</script>

<style lang="scss">
.leg {
  $background-color-transition: background-color 0.2s ease-in-out;

  display: flex;
  cursor: pointer;
  border-radius: $border-radius-medium;
  transition: $background-color-transition;
  // padding-right: 1rem;

  &.mode_WALK {
    overflow: hidden;
    display: none;

    .itinerary_open &,
    &:first-child:last-child {
      display: flex;
    }
  }

  &__label {
    flex-basis: 18%;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      left: 100%;
      top: 0;
      width: 2px;
      border-radius: 100px;

      .mode_WALK & {
        background-size: contain;
        background-image: url(../images/walk-bg-vert.svg);
      }

      @include color-by-mode("", &, background-color);
    }
  }

  &__number {
    padding: 0.2rem 0.25rem;
    border-radius: 0.15rem;
    margin-top: 0.2rem;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1em;

    @include color-by-mode("", &, color);
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    padding-left: 1.25rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 0.15rem;
    padding-left: 1.25rem;
    padding-right: 0;
    transition: padding 0.2s ease-in-out;
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    row-gap: .1rem;
    column-gap: .25rem;
    margin-bottom: .15rem;
  }

  &__short-name {
    color: $c-white;
    font-weight: 700;
    font-size: .7em;
    padding: .15rem .5rem;
    border-radius: .25rem;

    @include color-by-mode("", &, background-color);
  }

  &__headsign {
    font-weight: 700;
    font-size: .85rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    position: relative;

    @include color-by-mode("", &, color);

    &:before {
      content: "→";
      display: block;
      margin-right: .25rem;
      height: 50%;
      line-height: .5rem;
    }
  }

  &__name {
    font-size: 0.75rem;

    .leg:hover & {
      text-decoration: underline;
    }
  }

  &__distance {
    font-weight: 400;
  }

  &__duration {
    opacity: 0.75;
    font-size: 0.85rem;
  }

  &__waiting {
    flex-basis: 100%;
    font-size: 0.75rem;
    opacity: 0.65;
    margin-top: 0.25rem;
  }

  &__stop {
    display: flex;
    align-items: baseline;
    font-size: 0.95rem;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  &__stop-name {
    font-weight: 700;
    font-size: 1rem;
    flex-grow: 1;
    display: flex;
    align-items: baseline;

    &:after {
      content: "";
      display: block;
      flex-grow: 1;
      padding-bottom: 10px;
      border-bottom: 2px dotted #ccc;
      margin: 0 0.5rem;
    }
  }

  &__stop-time {
    opacity: 0.75;

    &_departure {
      font-weight: 700;
      opacity: 1;
    }
  }
}
</style>
