export enum FocusType {
    Itinerary, Leg
}

/**
 * Represents a vehicle marker on the map.
 */
export default class Focus {
    readonly type: FocusType;

    // Index of focused itinerary
    public itinerary: number | null;

    // Index of focused leg
    public leg: number | null;
    readonly savedItineraries: boolean;
    public hasMoved: boolean = false;

    constructor(
        type,
        itinerary: number | null = null,
        savedItineraries = false,
        leg: number | null = null
    ) {
        this.type = type;
        this.itinerary = itinerary;
        this.savedItineraries = savedItineraries;
        this.leg = leg;
    }
}
